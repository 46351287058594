<div class="container-fluid">
  <div class="jumbotron jumbotron-fluid bg-primary">
    <ng-container *ngIf="!ns?.small; else mobileView">
      <div class="row h-100 w-100 bg-primary p-0 m-0">
        <div class="col-6 bg-primary p-0">
          <div class="row h-100 d-flex">
            <div class="h-50 w-100 bg-primary align-items-left d-flex flex-column justify-content-center p-4 px-5">
              <p class="text-goldenRod font-weight-bold mt-4 text-left text-uppercase">{{'home.tag' | translate}}</p>
              <h1 class="text-white">
                <span class="text-goldenRod">{{wordCarousel[lang][wordCounter]}}</span>&nbsp;{{'home.title' |translate}}
              </h1>
              <div class="text-white pr-5">{{'home.text' | translate}}</div>
            </div>
            <div class="w-100 h-50">
              <video [hidden]="!horizontalVideo" #videoH1 autoplay loop muted playsinline="true"
                webkit-playsinline="true" loop="loop" src="../../assets/video/hospital.mp4" type="video/mp4"></video>
              <video [hidden]="horizontalVideo" #videoH2 autoplay loop muted playsinline="true"
                webkit-playsinline="true" loop="loop" src="../../assets/video/hand.mp4" type="video/mp4">
              </video>
            </div>
          </div>
        </div>
        <div class="col-6 p-0">
          <video [hidden]="!verticalVideo" #videoV1 autoplay loop muted playsinline="true" webkit-playsinline="true"
            loop="loop" src="../../assets/video/walk.mp4" type="video/mp4">
          </video>
          <video [hidden]="verticalVideo" #videoV2 autoplay loop muted playsinline="true" webkit-playsinline="true"
            loop="loop" src="../../assets/video/screen.mp4" type="video/mp4">
          </video>
          <div style="position: absolute; bottom: 0; width: 100%; z-index: 100;" class="h-50 bg-secondary">
            <div class="row p-0 m-0 h-100">
              <div class="col-6 p-0 h-100 w-100 align-items-center d-flex flex-column justify-content-center"
                [class.order-2]="order" style="background-color: white !important;">
                <img class="align-middle custom-icon" [class.scale-in-center]="in" [class.scale-out-center]="out"
                  [src]="shape?.img">
              </div>
              <div class="col-6 p-0 align-items-left d-flex flex-column justify-content-center text-left px-5"
                [class.order-1]="order">
                <h1 class="text-primary shape-text">
                  {{shape?.text1[lang]}}
                </h1>
                <h1 class="text-primary shape-text">
                  {{shape?.text2[lang]}}
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-template #mobileView>
      <div class="row h-30 w-100 bg-primary m-0">
        <video [hidden]="!verticalVideo" #videoV1 autoplay loop muted playsinline="true" webkit-playsinline="true"
          loop="loop" src="../../assets/video/walk.mp4" type="video/mp4" style="min-height: 33vh;">
        </video>
        <video [hidden]="verticalVideo" #videoV2 autoplay loop muted playsinline="true" webkit-playsinline="true"
          loop="loop" src="../../assets/video/screen.mp4" type="video/mp4" style="min-height: 33vh;">
        </video>
      </div>
      <div class="row h-70 w-100 bg-primary px-2 m-0 mt-2">
        <div class="col">
          <p class="text-goldenRod font-weight-bold text-left">{{'home.tag' | translate}}</p>
          <h1 class="text-white mt-0">
            <span class="text-goldenRod">{{wordCarousel[lang][wordCounter]}}</span>&nbsp;{{'home.title' |
            translate}}
          </h1>
          <p class="text-white">{{'home.text' | translate}} </p>
        </div>
      </div>
    </ng-template>
  </div>
</div>



<!-- https://greensock.com/forums/topic/10181-animation-that-simulates-an-ekg/
https://codepen.io/Ace-Nectar/pen/szmBj
 -->

<div class="container-fluid first-section bg-tertiary pb-0">
  <div class="container section text-center pb-0">
    <h1>Empowering <span class="text-goldenRod">Healthcare</span></h1>
    <div class="lead mt-2 pb-0 mb-0 text-center">{{'home.sectors.text' | translate}}
    </div>
    <div class="row pb-5 h-100 mt-3">
      <div class="col-6 col-md-3 col-sm-6 col-sx-6 my-auto text-center">
        <div class="card card-block d-flex flex-column shadow text-primary">
          <div class="card-body align-items-center d-flex flex-column justify-content-center cb-1">
            <h3>
              <ion-icon src="assets/icons/hospital.svg"></ion-icon>
            </h3>
          </div>
          <div class="card-body align-items-center d-flex flex-column justify-content-center cb-2">
            <div class="text-primary body-font lead">{{'home.sectors.hrp' | translate}}</div>
          </div>
        </div>
      </div>

      <div class="col-6 col-md-3 col-sm-6 col-sx-6 my-auto text-center">
        <div class="card card-block d-flex flex-column shadow text-primary">
          <div class="card-body align-items-center d-flex flex-column justify-content-center cb-1">
            <h3>
              <ion-icon src="assets/icons/clinic.svg"></ion-icon>
            </h3>
          </div>
          <div class="card-body align-items-center d-flex flex-column justify-content-center cb-2">
            <div class="text-primary body-font lead">{{'home.sectors.cmp' | translate}}</div>
          </div>
        </div>
      </div>

      <div class="col-6 col-md-3 col-sm-6 col-sx-6 my-auto text-center">
        <div class="card card-block d-flex flex-column shadow text-primary">
          <div class="card-body align-items-center d-flex flex-column justify-content-center cb-1">
            <h3>
              <ion-icon src="assets/icons/admin.svg"></ion-icon>
            </h3>
          </div>
          <div class="card-body align-items-center d-flex flex-column justify-content-center cb-2">
            <div class="text-primary body-font lead">{{'home.sectors.aps' | translate}}</div>
          </div>
        </div>
      </div>

      <div class="col-6 col-md-3 col-sm-6 col-sx-6 my-auto text-center">
        <div class="card card-block d-flex flex-column shadow text-primary">
          <div class="card-body align-items-center d-flex flex-column justify-content-center cb-1">
            <h3>
              <ion-icon src="assets/icons/leaf.svg"></ion-icon>
            </h3>
          </div>
          <div class="card-body align-items-center d-flex flex-column justify-content-center cb-2">
            <div class="text-primary body-font lead">{{'home.sectors.ap' | translate}}</div>
          </div>
        </div>
      </div>

      <div class="col-6 col-md-3 col-sm-6 col-sx-6 my-auto text-center">
        <div class="card card-block d-flex flex-column shadow text-primary">
          <div class="card-body align-items-center d-flex flex-column justify-content-center cb-1">
            <h3>
              <ion-icon src="assets/icons/heart.svg"></ion-icon>
            </h3>
          </div>
          <div class="card-body align-items-center d-flex flex-column justify-content-center cb-2">
            <div class="text-primary body-font lead">{{'home.sectors.sr' | translate}}</div>
          </div>
        </div>
      </div>

      <div class="col-6 col-md-3 col-sm-6 col-sx-6 my-auto text-center">
        <div class="card card-block d-flex flex-column shadow text-primary">
          <div class="card-body align-items-center d-flex flex-column justify-content-center cb-1">
            <h3>
              <ion-icon src="assets/icons/medical-report.svg"></ion-icon>
            </h3>
          </div>
          <div class="card-body align-items-center d-flex flex-column justify-content-center cb-2">
            <div class="text-primary body-font lead">{{'home.sectors.cd' | translate}}</div>
          </div>
        </div>
      </div>

      <div class="col-6 col-md-3 col-sm-6 col-sx-6 my-auto text-center">
        <div class="card card-block d-flex flex-column shadow text-primary">
          <div class="card-body align-items-center d-flex flex-column justify-content-center cb-1">
            <h3>
              <ion-icon src="assets/icons/pharmacy.svg"></ion-icon>
            </h3>
          </div>
          <div class="card-body align-items-center d-flex flex-column justify-content-center cb-2">
            <div class="text-primary body-font lead">{{'home.sectors.fimd' | translate}}</div>
          </div>
        </div>
      </div>

      <div class="col-6 col-md-3 col-sm-6 col-sx-6 my-auto text-center">
        <div class="card card-block d-flex flex-column shadow text-primary">
          <div class="card-body align-items-center d-flex flex-column justify-content-center cb-1">
            <h3>
              <ion-icon src="assets/icons/charity.svg"></ion-icon>
            </h3>
          </div>
          <div class="card-body align-items-center d-flex flex-column justify-content-center cb-2">
            <div class="text-primary body-font lead">{{'home.sectors.amp' | translate}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
  <path fill-opacity="1" d="M0,160L1440,64L1440,0L0,0Z"></path>
</svg>
<div class="container-fluid  pb-5 pt-0 mt--6">
  <div class="container section first text-left">
    <div class="row">
      <div class="col-md-5">
        <h1>{{'home.clients.title' | translate}} <span class="text-goldenRod">{{'home.clients.clients' |
            translate}}</span></h1>
        <div class="lead mt-2">{{'home.clients.text' | translate}}</div>
        <a href="/clients">
          <p>{{'more' | translate}} →</p>
        </a>
      </div>
      <div class="col-md-7">
        <div class="client-grid pb-5">
          <img [hidden]="!clientsFlag" *ngFor="let i of cs?.clients1" src="../../assets/clients/{{i?.id}}.png">
          <img [hidden]="clientsFlag" *ngFor="let i of cs?.clients2" src="../../assets/clients/{{i?.id}}.png"
            [class.scale-in-center]="in" [class.scale-out-center]="out">
        </div>
      </div>
    </div>


  </div>
</div>

<div id="services" class="container-fluid py-4 bg-primary pb-0">
  <div class="container section text-white">
    <div class="row">
      <div class="col-12">
        <h1 class="text-white">{{'home.services.title1' | translate}}&nbsp;<span
            class="text-goldenRod">{{'home.services.title2' | translate}}</span></h1>
        <div class="lead mt-2">{{'home.services.text' | translate}}</div>
      </div>
    </div>
  </div>
</div>
<!-- <svg class="mt--2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
  <path fill-opacity="1" d="M0,160L1440,64L1440,0L0,0Z"></path>
</svg> -->
<!-- <div class="first-background"> -->
<div>
  <div class="container-fluid py-4">
    <div class="container section service-box">
      <div class="row">
        <div class="col-md-6 order-2 order-md-1">
          <h1>{{'services.xpa.title' | translate}}</h1>
          <p class="lead text-primary">{{'services.xpa.text' | translate}}</p>
          <p class="text-goldenRod text-uppercase">{{'services.xpa.k1' | translate}} · {{'services.xpa.k2' | translate}}
            ·
            {{'services.xpa.k3' | translate}} · {{'services.xpa.k4' | translate}}</p>
        </div>
        <div class="col-md-6 align-middle my-auto text-center order-1 order-md-2">
          <img class="fade-in-image custom-icon align-middle" src="../../assets/img/1-home/heart01.svg">
        </div>
      </div>
    </div>
  </div>
  <!-- <svg class="section-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
  <path fill="#0099ff" fill-opacity="1"
    d="M0,160L120,138.7C240,117,480,75,720,58.7C960,43,1200,53,1320,58.7L1440,64L1440,0L1320,0C1200,0,960,0,720,0C480,0,240,0,120,0L0,0Z">
  </path>
</svg> -->


  <div class="container-fluid py-4">
    <div class="container section service-box small-0">
      <div class="row">
        <div class="col-md-6 order-2 order-md-2">
          <h1>{{'services.sp.title' | translate}}</h1>
          <p class="lead text-primary">{{'services.sp.text' | translate}}</p>
          <p class="text-goldenRod text-uppercase">{{'services.sp.k1' | translate}} · {{'services.sp.k2' | translate}} ·
            {{'services.sp.k3' | translate}} · {{'services.sp.k4' | translate}}</p>
        </div>
        <div class="col-md-6 align-middle my-auto order-1 order-md-1 text-center">
          <img class="fade-in-image custom-icon align-middle" src="../../assets/img/1-home/triangle01.svg">
        </div>
      </div>
    </div>
  </div>

  <div class="container-fluid py-4">
    <div class="container section service-box small-0">
      <div class="row">
        <div class="col-md-6 order-2 order-md-1">
          <h1>{{'services.digital.title' | translate}}</h1>
          <p class="lead text-primary">{{'services.digital.text' | translate}}</p>
          <p class="text-goldenRod text-uppercase">{{'services.digital.k1' | translate}} · {{'services.digital.k2' |
            translate}} ·
            {{'services.digital.k3' | translate}} · {{'services.digital.k4' | translate}}</p>
        </div>
        <div class="col-md-6 align-middle my-auto text-center order-1 order-md-2">
          <img class="fade-in-image custom-icon align-middle" src="../../assets/img/1-home/nube01.svg">
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container-fluid py-4">
  <div class="container section service-box small-0">
    <div class="row">
      <div class="col-md-6 order-2 order-md-2">
        <h1>{{'services.op.title' | translate}}</h1>
        <p class="lead text-primary">{{'services.op.text' | translate}}</p>
        <p class="text-goldenRod text-uppercase">{{'services.op.k1' | translate}} · {{'services.op.k2' | translate}} ·
          {{'services.op.k3' | translate}} · {{'services.op.k4' | translate}}</p>
      </div>
      <div class="col-md-6 align-middle my-auto order-1 order-md-1 text-center">
        <img class="fade-in-image custom-icon align-middle" src="../../assets/img/1-home/rombo01.svg">
      </div>
    </div>
  </div>
</div>


<div class="container-fluid py-4">
  <div class="container section service-box small-0">
    <div class="row">
      <div class="col-md-6 order-2 order-md-1">
        <h1>{{'services.eo.title' | translate}}</h1>
        <p class="lead text-primary">{{'services.eo.text' | translate}}</p>
        <p class="text-goldenRod text-uppercase">{{'services.eo.k1' | translate}} · {{'services.eo.k2' | translate}} ·
          {{'services.eo.k3' | translate}} · {{'services.eo.k4' | translate}}</p>
      </div>
      <div class="col-md-6 align-middle my-auto text-center order-1 order-md-2">
        <img class="fade-in-image custom-icon align-middle" src="../../assets/img/1-home/rodona01.svg">
      </div>
    </div>
  </div>
</div>

<div class="container-fluid py-4 bg-tertiary">
  <div class="container section pb-8">
    <div class="row">
      <div class="col-md-7 text-left">
        <h1>{{'home.methodology.title1' | translate}}</h1>
      </div>
      <div class="col-md-5 text-left">
        <div class="lead">{{'home.methodology.text1' | translate}}</div>
      </div>
    </div>
  </div>
</div>






<div class="container-fluid py-4 mt--10">
  <div class="container section text-center">
    <div class="row">
      <div class="col-md-12 ib-container text-left">
        <div class="ib-box w-100">
          <div class="row">
            <div class="col-2 col-md-6"></div>
            <div class="col-10 col-md-6 bg-white p-4">
              <h2>{{'home.methodology.title2' | translate}}</h2>
              <p>{{'home.methodology.text2' | translate}}</p>
              <!-- <a href="/methodology" class="text-goldenRod">
                <p>{{'more' | translate}} →</p> 
              </a>-->
            </div>
          </div>
        </div>
      </div>
    </div>

    <h1 class="mt-6 mb-6">100<span style="font-size: 2rem;">%</span> {{'home.sector.title1' | translate}}&nbsp;<span class="text-goldenRod">{{'home.sector.title2'
        |
        translate}}</span></h1>
    <div class="row d-inline-flex w-100">
      <div class="col-6 col-md-3 text-center mb-3">
        <div class="stats-box w-100">+15</div>
        <div>{{'home.sector.b1' | translate}}</div>
      </div>
      <div class="col-6 col-md-3 text-center mb-3">
        <div class="stats-box  w-100">+50</div>
        <div>{{'home.sector.b3' | translate}}</div>
      </div>
      <div class="col-6  col-md-3 text-center mb-3">
        <div class="stats-box  w-100">+200</div>
        <div>{{'home.sector.b4' | translate}}</div>
      </div>
      <div class="col-6 col-md-3 text-center mb-3">
        <div class="stats-box w-100">+30</div>
        <div>{{'home.sector.b2' | translate}}</div>
      </div>
    </div>
    <a href="/about-us">
      <p>{{'more' | translate}} →</p>
    </a>
  </div>
</div>



<!-- <div class="container-fluid py-4 bg-secondary">
  <div class="container section wave">
    <div class="row">
      <div class="col-12 col-md-4 text-left text-md-center mb-3">
        <a href="tel:+34931297570" class="text-white">
          <div class="text-primary lead"><i class="bi bi-phone"></i>&nbsp;931297570</div>
        </a>
      </div>
      <div class="col-12 col-md-4 text-left text-md-center mb-3">
        <a href="mailto:info@atheneasolutions.com" class="text-white">
          <div class="text-primary lead"><i class="bi bi-envelope"></i>&nbsp;info@atheneasolutions.com</div>
        </a>
      </div>
      <div class="col-12 col-md-4 text-left text-md-center">
        <a href="https://goo.gl/maps/TvEETXU1GiaHc1Y87" target="_blank" class="text-white">
          <div class="text-primary lead"><i class="bi bi-pin-map"></i>&nbsp;Barcelona</div>
        </a>
      </div>
    </div>
  </div>
</div> -->

<div class="container-fluid contact-background">
  <div class="container section wave">
    <h1 class="mt-3 mb-5 text-center text-primary bg-secondary p-3" >{{'home.contact.title' | translate}}</h1>

    <div class="row row d-flex justify-content-center">
      <div class="col-md-2"></div>
      <div class="col-md-4 my-auto text-center mt-1">
        <div class="card card-block d-flex flex-column shadow text-primary">
          <div class="card-body align-items-center d-flex flex-column justify-content-center">
            <h4>
              <ion-icon name="mail-outline" size="large"></ion-icon>
            </h4>
            <h5 class="p-2 text-primary body-font">{{'contact.email.text' | translate}}</h5>
            <a href="mailto:info@atheneasolutions.com" target="_blank"><button type="button"
                class="btn btn-primary btn-sm">{{'contact.email.button' | translate}}</button></a>

          </div>
        </div>
      </div>
      <div class="col-md-4 my-auto text-center mt-1">
        <div class="card card-block d-flex flex-column shadow text-primary">
          <div class="card-body align-items-center d-flex flex-column justify-content-center">
            <h4>
              <ion-icon name="phone-portrait-outline" size="large"></ion-icon>
            </h4>
            <h5 class="p-2 text-primary body-font">{{'contact.phone.text' | translate}}</h5>
            <a href="tel:+34931297570" target="_blank" class="text-primary"><button type="button"
                class="btn btn-primary btn-sm">{{'contact.phone.button' | translate}}</button></a>
          </div>
        </div>
      </div>
      <div class="col-md-2"></div>

      <!-- <div class="col-md-4 my-auto text-center mt-1">
        <div class="card card-block d-flex flex-column shadow text-primary">
          <div class="card-body align-items-center d-flex flex-column justify-content-center">
            <h4>
              <ion-icon name="business-outline" size="large"></ion-icon>
            </h4>
            <h5 class="p-2 text-primary body-font">{{'contact.office.text' | translate}}</h5>
            <a href="https://goo.gl/maps/TvEETXU1GiaHc1Y87" target="_blank" class="text-primary"><button type="button"
                class="btn btn-primary btn-sm">{{'contact.office.button' |
                translate}}</button></a>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</div>
