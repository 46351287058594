<div class="container-fluid bg-primary">
    <div class="container section wave">
        <div class="row">
            <div class="col-12 text-left">
                <p class="text-goldenRod font-weight-bold text-uppercase">{{'menu.aboutus' | translate}}</p>
                <h1 class="text-white">{{'aboutus.title1' | translate}}</h1>
                <h1 class="text-white">{{'aboutus.title2' | translate}}</h1>
                <p class="text-white">{{'aboutus.text' | translate}}</p>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid py-4 bg-tertiary">
    <div class="container section wave">
        <div class="row">
            <!-- <div class="col-md-4 align-items-center d-flex flex-column justify-content-center">
                <img src="../../../assets/img/0-athenea/logo_centrat.png" class="img-fluid p-4">
            </div> -->

            <div class="col-md-12 text-left">
                <div class="lead">{{'aboutus.b1.text1' | translate}}</div>
                <!-- <p class=" font-weight-bold">{{'aboutus.b1.text3' | translate}}</p> -->
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-md-6 mt-2">
                <div class="card p-4 custom-card shadow">
                    <!-- <div class="line-box">
                        <div class="line-bottom"></div>
                    </div> -->
                    <!-- <img src=""> -->
                    <div>
                        <h2 class="">{{'aboutus.mission.title1' | translate}}<span
                                class="text-goldenRod">&nbsp;{{'aboutus.mission.title2' | translate}}</span></h2>
                        <p>{{'aboutus.mission.text1' | translate}}</p>
                        <p>{{'aboutus.mission.text2' | translate}}</p>
                    </div>

                </div>
            </div>
            <div class="col-md-6  h-100 custom-card mt-2">
                <div class="card p-4 h-100 custom-card shadow">
                    <!-- <img src="" alt="Snow" style="width:100%;"> -->
                    <div>
                        <h2 class="">{{'aboutus.vision.title1' | translate}}<span
                                class="text-goldenRod">&nbsp;{{'aboutus.vision.title2' | translate}}</span></h2>
                        <p>{{'aboutus.vision.text1' | translate}}</p>
                        <p>{{'aboutus.vision.text2' | translate}}</p>
                    </div>

                </div>
            </div>
        </div>
        <div class="row pt-4">
            <div class="col-md-12">
                <div class="card p-4 shadow">
                    <h2 class="text-center p-1 text-center">{{'aboutus.values.title1' | translate}}&nbsp;<span
                            class="text-goldenRod">{{'aboutus.values.title2' | translate}}</span></h2>
                    <div class="row py-4">
                        <div class="col-6 col-md-3 col-sm-6 col-sx-6 my-auto text-center mt-0-custom">
                            <div class="d-flex flex-column  text-primary">
                                <div
                                    class="card-body align-items-center d-flex flex-column justify-content-center cb-1 p-0">
                                    <h1>
                                        <ion-icon src="assets/icons/aboutus/certificate.svg"></ion-icon>
                                    </h1>
                                </div>
                                <div
                                    class="card-body align-items-center d-flex flex-column justify-content-center cb-2 p-0">
                                    <div class="text-primary body-font lead">{{'aboutus.values.b1.title' | translate}}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-6 col-md-3 col-sm-6 col-sx-6 my-auto text-center mt-0-custom">
                            <div class="d-flex flex-column  text-primary">
                                <div
                                    class="card-body align-items-center d-flex flex-column justify-content-center cb-1 p-0">
                                    <h1>
                                        <ion-icon src="assets/icons/aboutus/health-insurance.svg"></ion-icon>
                                    </h1>
                                </div>
                                <div
                                    class="card-body align-items-center d-flex flex-column justify-content-center cb-2 p-0">
                                    <div class="text-primary body-font lead">{{'aboutus.values.b2.title' | translate}}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-6 col-md-3 col-sm-6 col-sx-6 my-auto text-center mt-0-custom">
                            <div class="d-flex flex-column  text-primary">
                                <div
                                    class="card-body align-items-center d-flex flex-column justify-content-center cb-1 p-0">
                                    <h1>
                                        <ion-icon src="assets/icons/aboutus/send.svg"></ion-icon>
                                    </h1>
                                </div>
                                <div
                                    class="card-body align-items-center d-flex flex-column justify-content-center cb-2 p-0">
                                    <div class="text-primary body-font lead">{{'aboutus.values.b3.title' | translate}}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-6 col-md-3 col-sm-6 col-sx-6 my-auto text-center mt-0-custom">
                            <div class="d-flex flex-column  text-primary">
                                <div
                                    class="card-body align-items-center d-flex flex-column justify-content-center cb-1 p-0">
                                    <h1>
                                        <ion-icon src="assets/icons/aboutus/handshake.svg"></ion-icon>
                                    </h1>
                                </div>
                                <div
                                    class="card-body align-items-center d-flex flex-column justify-content-center cb-2 p-0">
                                    <div class="text-primary body-font lead">{{'aboutus.values.b4.title' | translate}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="col-md-6 text-left">
                        <p class="font-weight-bold">{{'aboutus.values.b1.title' | translate}}</p>
                        <p>{{'aboutus.values.b1.text' | translate}}</p>
                    </div>
                    <div class="col-md-6 text-left">
                        <p class="font-weight-bold">{{'aboutus.values.b2.title' | translate}}</p>
                        <p>{{'aboutus.values.b2.text' | translate}}</p>
                    </div>
                    <div class="col-md-6 text-left">
                        <p class="font-weight-bold">{{'aboutus.values.b3.title' | translate}}</p>
                        <p>{{'aboutus.values.b3.text' | translate}}</p>
                    </div>
                    <div class="col-md-6 text-left">
                        <p class="font-weight-bold">{{'aboutus.values.b4.title' | translate}}</p>
                        <p>{{'aboutus.values.b4.text' | translate}}</p>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</div>
<!-- <div class="container-fluid bg-tertiary-gradient">
    <div class="container section wave">
        <div class="row mt--8">
            <div class="col-md-12">
                <img src="../../../assets/img/4-aboutus/room7.jpeg" class="img-fluid" height="30%">
            </div>
        </div> 
        <div class="row mt-3">
            <div class="col-md-6">
                <div class="box-image">
                    <img src="../../../assets/img/4-aboutus/step.jpg" alt="Snow" style="width:100%;">
                    <div class="text-box">
                        <h2 class="text-header">{{'aboutus.mission.title' | translate}}</h2>

                        <p>{{'aboutus.mission.text1' | translate}}</p>
                        <p>{{'aboutus.mission.text2' | translate}}</p>
                    </div>

                </div>
            </div>
            <div class="col-md-6">
                <div class="box-image">
                    <img src="../../../assets/img/4-aboutus/computer.jpg" alt="Snow" style="width:100%;">
                    <div class="text-box">
                        <h2 class="text-header">{{'aboutus.vision.title' | translate}}</h2>

                        <p>{{'aboutus.vision.text1' | translate}}</p>
                        <p>{{'aboutus.vision.text2' | translate}}</p>
                    </div>

                </div>
            </div>
        </div>

    </div>
</div> -->


<!-- <div class="container-fluid">
    <div class="container section wave">
        <div class="row">
            <div class="col-md-6">
                <h1>{{'aboutus.team.title' | translate}}</h1>
                <p>{{'aboutus.team.text1' | translate}}</p>
                <p>{{'aboutus.team.text2' | translate}}</p>
            </div>
            <div class="col-md-6">
                <img src="../../../assets/img/4-aboutus/equip_5.jpg" class="img-fluid">
            </div>
        </div>
    </div>
</div> -->
<!-- <div class="container-fluid">
    <div class="container section wave">
        <div class="row">
            <div class="col-md-3">
                <div class="profile-card">

                    <div class="img-container">
                        <img class="custom-icon img-fluid" src="../../../assets/img/4-aboutus/icon.png">
                        <a href="https://www.linkedin.com/in/damiapujol/" target="_blank"><i
                                class="icon bi bi-linkedin"></i></a>
                    </div>
                    <p class="lead name">Damià Pujol</p>
                    <p class="role">Socio Fundador</p>
                </div>
            </div>
            <div class="col-md-3">
                <div class="profile-card">
                    <div class="img-container">
                        <img class="custom-icon img-fluid" src="../../../assets/img/4-aboutus/icon.png">
                        <a href="https://www.linkedin.com/in/ignasinicolas/" target="_blank"><i
                                class="icon bi bi-linkedin"></i></a>
                    </div>
                    <p class="lead name">Ignasi Nicolàs</p>
                    <p class="role">Socio Fundador</p>
                </div>
            </div>
            <div class="col-md-3">
                <div class="profile-card">
                    <div class="img-container">
                        <img class="custom-icon img-fluid" src="../../../assets/img/1-home/rombo01.svg">
                    </div>
                    <p class="lead name">Lluís Soler</p>
                    <p class="role">Responsable Sistemas de Calidad</p>
                </div>
            </div>
            <div class="col-md-3">
                <div class="profile-card">
                    <div class="img-container">
                        <img class="custom-icon img-fluid" src="../../../assets/img/1-home/nube01.svg">
                        <a href="https://www.linkedin.com/in/joan-albert-l%C3%B3pez-vallverd%C3%BA-b471193b/"
                            target="_blank"><i class="icon bi bi-linkedin"></i></a>
                    </div>
                    <p class="lead name">Joan Albert López</p>
                    <p class="role">Data Science Manager</p>
                </div>
            </div>
            <div class="col-md-3">
                <div class="profile-card">
                    <div class="img-container">
                        <img class="img-fluid custom-icon" src="../../../assets/img/1-home/nube01.svg">
                        <a href="https://www.linkedin.com/in/polfc/" target="_blank"><i
                                class="icon bi bi-linkedin"></i></a>
                    </div>
                    <p class="lead name">Pol Fernández</p>
                    <p class="role">mHealth Manager</p>
                </div>
            </div>
            <div class="col-md-3">
                <div class="profile-card">
                    <div class="img-container">
                        <img class="custom-icon img-fluid" src="../../../assets/img/1-home/heart01.svg">
                        <a href="https://www.linkedin.com/in/andrea-santamaria-guasch/" target="_blank"><i
                                class="icon bi bi-linkedin"></i></a>
                    </div>
                    <p class="lead name">Andrea Santamaria</p>
                    <p class="role">Responsable Experiencia del Paciente</p>
                </div>
            </div>

            <div class="col-md-3">
                <div class="profile-card">
                    <div class="img-container">
                        <img class="custom-icon img-fluid" src="../../../assets/img/1-home/nube01.svg">
                    </div>
                    <p class="lead name">Lluc Bové</p>
                    <p class="role">IT Services Manager</p>
                </div>
            </div>
            <div class="col-md-3">
                <div class="profile-card">
                    <div class="img-container">
                        <img class="custom-icon img-fluid" src="../../../assets/img/1-home/triangle01.svg">
                        <a href="https://www.linkedin.com/in/lvillargomez/" target="_blank"><i
                                class="icon bi bi-linkedin"></i></a>
                    </div>
                    <p class="lead name">Laura Villar</p>
                    <p class="role">Contact Health Center<br>Business Development</p>
                </div>
            </div>
            <div class="col-md-3">
                <div class="profile-card">
                    <div class="img-container">
                        <img class="custom-icon img-fluid" src="../../../assets/img/1-home/rodona01.svg">
                        <a href="https://www.linkedin.com/in/albert-parra-tresserra-4a5738191/" target="_blank"><i
                                class="icon bi bi-linkedin"></i></a>
                    </div>
                    <p class="lead name">Albert Parra</p>
                    <p class="role">Responsable Operaciones Estratégicas</p>
                </div>
            </div>
            <div class="col-md-3">
                <div class="profile-card">
                    <div class="img-container">
                        <img class="custom-icon img-fluid" src="../../../assets/img/1-home/heart01.svg">
                    </div>
                    <p class="lead name">Marcos Garcia</p>
                    <p class="role">Lores Ipsum, Example</p>
                </div>
            </div>
        </div>
    </div>
</div> -->


<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320" class="mt--4">
    <path fill-opacity="1" d="M0,160L1440,64L1440,0L0,0Z"></path>
</svg>
<div class="container-fluid mt--17-custom">
    <div class="container section text-center">

        <h1 class="mb-6">100<span style="font-size: 2rem;">%</span> {{'home.sector.title1' | translate}}&nbsp;<span
                class="text-goldenRod">{{'home.sector.title2'
                |
                translate}}</span></h1>
        <div class="row d-inline-flex w-100">
            <div class="col-6 col-md-3 text-center mb-3">
                <div class="stats-box w-100">+15</div>
                <div>{{'home.sector.b1' | translate}}</div>
            </div>
            <div class="col-6 col-md-3 text-center mb-3">
                <div class="stats-box  w-100">+50</div>
                <div>{{'home.sector.b3' | translate}}</div>
            </div>
            <div class="col-6  col-md-3 text-center mb-3">
                <div class="stats-box  w-100">+200</div>
                <div>{{'home.sector.b4' | translate}}</div>
            </div>
            <div class="col-6 col-md-3 text-center mb-3">
                <div class="stats-box w-100">+30</div>
                <div>{{'home.sector.b2' | translate}}</div>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid">
    <div class="container section wave mt--3">
        <div class="row mb-3">
            <div class="col-md-12">
                <h1 class="text-center mb-5">{{'aboutus.team.title1' | translate}}&nbsp;<span
                        class="text-goldenRod">{{'aboutus.team.title2' | translate}}</span></h1>
                <p>{{'aboutus.team.text1' | translate}}</p>
                <p>{{'aboutus.team.text2' | translate}}</p>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="ib-container">
                <div class="ib-box w-100">
                    <div class="row">
                        <div class="col-2 col-md-6"></div>
                        <div class="col-10 col-md-6 bg-white p-3">
                            <h2>{{'aboutus.recrute.title' | translate}}</h2>
                            <p>{{'aboutus.recrute.text' | translate}}</p>
                            <a href="mailto:info@atheneasolutions.com" target="_blank" class="text-goldenRod">
                                <p>info@atheneasolutions.com</p>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        </div>
    </div>