import { COLORS } from "./colors";

export class ParticlesOptions {
    static default = {
        background: {
            color: {
                value: 'transparent'
            }
        },
        fpsLimit: 60,
        interactivity: {
            detectsOn: 'window',
            events: {
                onClick: {
                    enable: true,
                    mode: 'push'
                },
                onHover: {
                    enable: true,
                    mode: 'repulse'
                },
                resize: true
            },
            modes: {
                bubble: {
                    distance: 400,
                    duration: 2,
                    opacity: 0.8,
                    size: 40,
                    speed: 0.1
                },
                push: {
                    quantity: 4
                },
                repulse: {
                    distance: 50,
                    duration: 1
                }
            }
        },
        particles: {
            color: {
                value: COLORS.PRIMARY
            },
            links: {
                color: COLORS.PRIMARY,
                distance: 100,
                enable: true,
                opacity: 0.6,
                width: 1
            },
            collisions: {
                enable: true
            },
            move: {
                direction: 'none',
                enable: true,
                outMode: 'bounce',
                random: false,
                speed: 1,
                straight: false
            },
            number: {
                density: {
                    enable: true,
                    value_area: 800
                },
                value: 80
            },
            opacity: {
                value: 0.5
            },
            shape: {
                type: 'circle'
            },
            size: {
                random: true,
                value: 5
            }
        },
        detectRetina: true
    };


    static dark = {
        background: {
            color: {
                value: COLORS.PRIMARY
            }
        },
        fpsLimit: 60,
        interactivity: {
            detectsOn: 'window',
            events: {
                onClick: {
                    enable: true,
                    mode: 'push'
                },
                onHover: {
                    enable: true,
                    mode: 'repulse'
                },
                resize: true
            },
            modes: {
                bubble: {
                    distance: 400,
                    duration: 2,
                    opacity: 0.8,
                    size: 40,
                    speed: 0.1
                },
                push: {
                    quantity: 4
                },
                repulse: {
                    distance: 50,
                    duration: 1
                }
            }
        },
        particles: {
            color: {
                value: "#ffffff"
            },
            links: {
                color: "#ffffff",
                distance: 100,
                enable: true,
                opacity: 0.6,
                width: 1
            },
            collisions: {
                enable: true
            },
            move: {
                direction: 'none',
                enable: true,
                outMode: 'bounce',
                random: false,
                speed: 1,
                straight: false
            },
            number: {
                density: {
                    enable: true,
                    value_area: 800
                },
                value: 80
            },
            opacity: {
                value: 1
            },
            shape: {
                type: 'circle'
            },
            size: {
                random: true,
                value: 5
            }
        },
        detectRetina: true
    };

    static bigParticles = {
        background: {
            color: {
                value: 'transparent'
            }
        },
        detectRetina: true,
        fpsLimit: 60,
        interactivity: {
            detectsOn: 'window',
            events: {
                // onClick: {
                //     enable: true,
                //     mode: 'push'
                // },
                onHover: {
                    enable: true,
                    mode: 'repulse',
                },
                resize: false
            },
            modes: {
                bubble: {
                    distance: 10,
                    duration: 2,
                    opacity: 0.8,
                    size: 40,
                    speed: 0.1
                },
                push: {
                    quantity: 1
                },
                repulse: {
                    distance: 200,
                    duration: 5
                }
            }
        },
        particles: {
            color: {
                value: [
                    COLORS.SECONDARYDARK,
                    COLORS.SECONDARY,
                    COLORS.SECONDARYMED,
                    COLORS.SECONDARYMEDDARK,
                ]
            },
            move: {
                angle: {
                    offset: 45,
                    value: 90
                },
                direction: "top",
                enable: true,
                random: true,
                size: true,
                speed: 2,
                straight: true,
            },
            number: {
                limit: 10,
                value: 25
            },
            opacity: {
                random: {
                    enable: true,
                    minimumValue: 0.2
                },
                value: {
                    min: 0.2,
                    max: 1
                },
                animation: {
                    count: 1,
                    enable: true,
                    speed: 1,
                    sync: false,
                    destroy: "none",
                    minimumValue: 0.2,
                    startValue: "random"
                }
            },
            reduceDuplicates: false,
            shape: {
                options: {},
                type: "square"
            },
            size: {
                random: {
                    enable: true,
                    minimumValue: 5
                },
                value: {
                    min: 5,
                    max: 200
                },
                animation: {
                    count: 0,
                    enable: true,
                    speed: 40,
                    sync: false,
                    destroy: "none",
                    minimumValue: 5,
                    startValue: "random"
                }
            },
        }
    }
}