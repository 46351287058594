import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { AboutusComponent } from './pages/aboutus/aboutus.component';
import { ClientsComponent } from './pages/clients/clients.component';
import { ContactComponent } from './pages/contact/contact.component';
import { CookiesComponent } from './pages/cookies/cookies.component';
import { ExpaComponent } from './pages/expa/expa.component';
import { PrivacyComponent } from './pages/privacy/privacy.component';
import { ServicesComponent } from './pages/services/services.component';


const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'expa', component: ExpaComponent },
  { path: 'about-us', component: AboutusComponent },
  { path: 'clients', component: ClientsComponent },
  { path: 'contact', component: ContactComponent },
  { path: 'cookies', component: CookiesComponent },
  { path: 'privacy', component: PrivacyComponent },
  { path: 'privacy/:lang', component: PrivacyComponent },
  { path: 'services', component: ServicesComponent },
  { path: '', redirectTo: '', pathMatch: 'full' },
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes)
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule { }
