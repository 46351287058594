export class COLORS {
    static DOGNUT = ["red", "#fadb5f", "#f0b429", "#c5f7e2", "#147d64", "#8299b1"];
    static PRIMARY = '#2d3f5b';
    static PRIMARY_DARKER = '#014d40';
    static PRIMARY_LIGHT = 'rgba(45, 63, 91, 0.1);';

    static SECONDARY = '#b5fbee';
    static SECONDARYDARK = '#a5e6da';
    static SECONDARYMED = "#b5fbee";
    static SECONDARYMEDDARK = "#a9e4da";
    static GRAY = '#8299b1';
    static GRAYDARKER = '#243b53';
    static MULTIPLE = ["#fadb5f", "#f0b429", "#486481", "#c5f7e2", "#8299b1", "#d9e2ed", "#186faf", "#84c5f4", "#d1463f", "#f68f8d"]

    static GOLDENROD = "#daa520";


}