<div class="container-fluid bg-primary">
    <div class="container section wave">
        <div class="row">
            <div class="col-12 text-left">
                <p class="text-goldenRod font-weight-bold text-uppercase">{{'menu.services' | translate}}</p>
                <h1 class="text-white">{{'services.title' | translate}}</h1>
                <p class="text-white">{{'home.services.text' | translate}}</p>
            </div>
        </div>
    </div>
</div>



<div class="container-fluid py-4">
    <div class="container section service-box">
        <div class="row">
            <div class="col-md-6 order-2 order-md-1">
                <h1>{{'services.xpa.title' | translate}}</h1>
                <p class="lead text-primary">{{'services.xpa.text' | translate}}</p>
                <p class="text-goldenRod text-uppercase">{{'services.xpa.k1' | translate}} · {{'services.xpa.k2' |
                    translate}} · {{'services.xpa.k3' | translate}} · {{'services.xpa.k4' | translate}}</p>
            </div>
            <div class="col-md-6 align-middle my-auto text-center order-1 order-md-2">
                <img class="fade-in-image custom-icon align-middle" src="../../assets/img/1-home/heart01.svg">
            </div>
        </div>
    </div>
</div>


<div class="container-fluid py-4">
    <div class="container section service-box small-0">
        <div class="row">
            <div class="col-md-6 order-2 order-md-2">
                <h1>{{'services.sp.title' | translate}}</h1>
                <p class="lead text-primary">{{'services.sp.text' | translate}}</p>
                <p class="text-goldenRod text-uppercase">{{'services.sp.k1' | translate}} · {{'services.sp.k2' |
                    translate}} ·
                    {{'services.sp.k3' | translate}} · {{'services.sp.k4' | translate}}</p>
            </div>
            <div class="col-md-6 align-middle my-auto order-1 order-md-1 text-center">
                <img class="fade-in-image custom-icon align-middle" src="../../assets/img/1-home/triangle01.svg">
            </div>
        </div>
    </div>
</div>

<div class="container-fluid py-4">
    <div class="container section service-box small-0">
        <div class="row">
            <div class="col-md-6 order-2 order-md-1">
                <h1>{{'services.digital.title' | translate}}</h1>
                <p class="lead text-primary">{{'services.digital.text' | translate}}</p>
                <p class="text-goldenRod text-uppercase">{{'services.digital.k1' | translate}} ·
                    {{'services.digital.k2' |
                    translate}} ·
                    {{'services.digital.k3' | translate}} · {{'services.digital.k4' | translate}}</p>
            </div>
            <div class="col-md-6 align-middle my-auto text-center order-1 order-md-2">
                <img class="fade-in-image custom-icon align-middle" src="../../assets/img/1-home/nube01.svg">
            </div>
        </div>
    </div>
</div>

<div class="container-fluid py-4">
    <div class="container section service-box small-0">
        <div class="row">
            <div class="col-md-6 order-2 order-md-2">
                <h1>{{'services.op.title' | translate}}</h1>
                <p class="lead text-primary">{{'services.op.text' | translate}}</p>
                <p class="text-goldenRod text-uppercase">{{'services.op.k1' | translate}} · {{'services.op.k2' |
                    translate}} ·
                    {{'services.op.k3' | translate}} · {{'services.op.k4' | translate}}</p>
            </div>
            <div class="col-md-6 align-middle my-auto order-1 order-md-1 text-center">
                <img class="fade-in-image custom-icon align-middle" src="../../assets/img/1-home/rombo01.svg">
            </div>
        </div>
    </div>
</div>


<div class="container-fluid py-4">
    <div class="container section service-box small-0">
        <div class="row">
            <div class="col-md-6 order-2 order-md-1">
                <h1>{{'services.eo.title' | translate}}</h1>
                <p class="lead text-primary">{{'services.eo.text' | translate}}</p>
                <p class="text-goldenRod text-uppercase">{{'services.eo.k1' | translate}} · {{'services.eo.k2' |
                    translate}} ·
                    {{'services.eo.k3' | translate}} · {{'services.eo.k4' | translate}}</p>
            </div>
            <div class="col-md-6 align-middle my-auto text-center order-1 order-md-2">
                <img class="fade-in-image custom-icon align-middle" src="../../assets/img/1-home/rodona01.svg">
            </div>
        </div>
    </div>