<nav id="navbar-main" [hidden]="ns?.hideMenu"
    class="navbar navbar-main navbar-expand-lg navbar-light bg-white mt-2 sticky-top" [class.box-shadow]="scroll">

    <a class="navbar-brand" href=""><img src="assets/img/0-athenea/logo_alineat.png" width="170" alt=""></a>
    <button #navbarToggler class="navbar-toggler" type="button" data-bs-toggle="collapse" data-target="#navBarContent"
        aria-controls="navBarContent" (click)="isShown = !isShown" aria-expanded="false" [attr.aria-expanded]="isShown">
        <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" [ngClass]="{ 'show': isShown }" id="navBarContent">
        <ul class="navbar-nav ml-auto">
            <!-- <li class="nav-item dropdown">
                <a class="nav-link dropdown-toggle" [class.web-dropdown]="!ns?.small" href="#"
                    id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    SERVICIOS
                </a>
                <div class="dropdown-menu" [class.web-dropdown]="!ns?.small" aria-labelledby="navbarDropdownMenuLink">
                    <a class="dropdown-item" href="/expa">Experiencia del Paciente</a>
                    <a class="dropdown-item" href="#">Seguridad del Paciente, Excelencia y Calidad</a>
                    <a class="dropdown-item" href="#">Digital Health y transformación digital</a>
                    <a class="dropdown-item" href="#">Optimización de Procesos y dispositivos</a>
                    <a class="dropdown-item" href="#">Estrategia y Organización</a>
                    <a class="dropdown-item" href="#">Datos, los valores de futuro</a>
                </div>
            </li> -->

            <li class="nav-item" [routerLinkActive]="['active']">
                <a class="nav-link text-uppercase" [routerLink]="['services']">{{'menu.services' | translate}}</a>
            </li>
            <li class="nav-item" [routerLinkActive]="['active']">
                <a class="nav-link text-uppercase" [routerLink]="['clients']">{{'menu.clients' | translate}}</a>
            </li>
            <li class="nav-item" [routerLinkActive]="['active']">
                <a class="nav-link text-uppercase" [routerLink]="['about-us']">{{'menu.aboutus' | translate}}</a>
            </li>
            <li class="nav-item" [routerLinkActive]="['active']">
                <a class="nav-link text-uppercase" [routerLink]="['contact']">{{'menu.contact' | translate}}</a>
            </li>
            <form class="form-inline">
                <button class="btn btn-sm btn-link text-secondary" [class.text-secondaryDark]="lang === 'ca'"
                    type="button" (click)="changeLang('ca')">CA</button>
                <button class="btn btn-sm btn-link text-secondary" [class.text-secondaryDark]="lang === 'es'"
                    type="button" (click)="changeLang('es')">ES</button>
                <!-- <button class="btn btn-sm btn-link" type="button">EN</button> -->
            </form>
        </ul>
        <!-- <div class="btn-group dropright" [class.dropleft]="!isShown">
            <button type="button nav-button" class="btn btn-secondary dropdown-toggle" data-toggle="dropdown"
                aria-haspopup="true" aria-expanded="false">
                <ngx-avatar [name]="profile?.username || '-'"></ngx-avatar>
            </button>
        </div> -->
    </div>
</nav>




<router-outlet></router-outlet>

<router-outlet></router-outlet>
<div *ngIf="!cookies" class="alert-box text-primary">
    <p>{{'home.warning.cookies1' | translate}}</p> 
    <p>{{'home.warning.cookies2' | translate}}&nbsp;<a href="/cookies">{{'home.warning.cookies3' | translate}}</a>.</p>
    <button class="btn btn-primary" (click)="acceptCookies()">{{'home.warning.accept' | translate}}</button>
</div>

<footer class="container-fluid bg-primary text-white">
    <div class="container-fluid py-4 bg-primary">
        <div class="container section wave pb-0">
            <div class="row">
                <div class="col-12 text-center">
                    <h1 class="text-white">{{'home.creativity.title1' | translate}}</h1>
                    <h1 class="text-white">{{'home.creativity.title2' | translate}}</h1>
                </div>
            </div>
        </div>
    </div>

    <div class="container section wave">
        <div class="row">

            <div class="col-md-2 text-align-center text-center px-2 pb-3">
                <a class="navbar-brand" href="#" style="margin-top: 20px;">
                    <img src="assets/img/0-athenea/icon_invert.png" style="max-width: 40px;" alt="">
                </a>
            </div>
            <div class="col-md-3 px-2">
                <div class="lead">Athenea Solutions</div>
                <p>{{'footer.team' | translate}}</p>
                <a href="https://www.linkedin.com/company/atheneasolutions" class="text-white" target="_blank">
                    <ion-icon name="logo-linkedin" size="large" class="ml-1"></ion-icon>
                </a>
                <a href="https://twitter.com/atheneasolution" class="text-white" target="_blank">
                    <ion-icon name="logo-twitter" size="large" class="ml-1"></ion-icon>
                </a>
                <!-- <a href="https://www.instagram.com/atheneasolutions/" class="text-white" target="_blank">
                    <ion-icon name="logo-instagram" size="large" class="ml-1"></ion-icon>
                </a> -->
            </div>
            <div class="col-md-1"></div>
            <div class="col-md-3 px-2">
                <p>
                    <a class="text-white lead" href="/services">{{'menu.services' | translate}}</a>
                </p>
                <p>
                    <a class="text-white lead" href="/clients">{{'menu.clients' | translate}}</a>
                </p>
                <p>
                    <a class="text-white lead" href="/about-us">{{'menu.aboutus' | translate}}</a>
                </p>
                <p><a class="text-white lead" href="/contact">{{'menu.contact' | translate}}</a></p>
            </div>
            <div class="col-md-3 px-2 links">
                <a href="/contact"><ion-icon name="send" size="large" color="white"></ion-icon></a>
                <a href="tel:+34931297570" class="text-white">
                    <p>+34 93 129 75 70</p>
                </a>
                <a href="mailto:info@atheneasolutions.com" target="_blank" class="text-white">
                    <p>info@atheneasolutions.com</p>
                </a>
                <a href="https://goo.gl/maps/TvEETXU1GiaHc1Y87" target="_blank" class="text-white">
                    <p>Carrer d'Hercegovina 19, despatx 3</p>
                    <p>08006 Barcelona</p>
                </a>
            </div>
        </div>
        <div class="row justify-content-center mt-2">
            <p><a class="text-white" href="/cookies">{{'footer.cookies' | translate}}</a> |&nbsp;</p>
            <p><a class="text-white" href="/privacy">{{'footer.privacy' | translate}}</a> |&nbsp;</p>
            <p>Ⓒ 2021 Athenea Solutions & Tech</p>
        </div>
    </div>
</footer>