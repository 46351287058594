<div class="container-fluid bg-primary">
    <div class="container section wave">
        <div class="row">
            <div class="col-12 text-left">
                <p class="text-goldenRod font-weight-bold text-uppercase">Experiencia del Paciente</p>
                <h1 class="text-white">La experiencia del paciente es uno de los motores hacia la sanidad del futuro.</h1>
            </div>
        </div>
    </div>
</div>
<div class="container-fluid">
    <div class="container section wave">
        <div class="row">
            <div class="col-md-7 text-left">
                <p>La experiencia del paciente es uno de los motores hacia la sanidad del futuro. Desde hace unos años
                    se considera una de las variables más importantes junto con la seguridad y la excelencia clínica,
                    tanto para usuarios como para profesionales.
                </p>
                <p>Creemos que los proyectos de experiencia del paciente impactan positivamente no sólo en el personal
                    de la organización y su metodología de trabajo, sino en la responsabilidad social de ésta. El
                    paciente ya no sólo espera de nosotros una atención de calidad, sino formar parte de sus decisiones
                    clínicas.
                </p>
            </div>
            <div class="col-md-5 text-left">
                <ul>
                    <li>Elaboración de patient journey map </li>
                    <li>Mapas de empatía</li>
                    <li>Definición de insights</li>
                    <li>Identificación de pain points</li>
                    <li>Shadowing</li>
                    <li>Grupos focales</li>
                    <li>Entrevistas en profundidad</li>
                    <li>Metodologías design thinking</li>
                    <li>Formación de formadores en experiencia del paciente</li>
                    <li>Evaluaciones de estado</li>
                </ul>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid bg-tertiary">
    <div class="container section wave">
        <div class="row">
            <div class="col-md-7 text-left">
                <h1>objetivo</h1>
            </div>
            <div class="col-md-5 text-left">
                <ul>
                    <li>Mejora de la imagen percibida y fidelización de los pacientes </li>
                    <li>Mejora de la motivación y el compromiso de los equipos asistenciales y profesionales</li>
                    <li>Reducción de reclamaciones</li>
                    <li>Mejora del índice de satisfacción y experiencia </li>
                </ul>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid">
    <div class="container section wave">
        <div class="row">
            <div class="col-md-12 text-center">
                <h1>Fases más destacadas de un proyecto de experiencia paciente</h1>

                <img class="img-fluid align-middle mt-4" src="../../../assets/img/2-expa/fases.png">
            </div>
        </div>
    </div>
</div>

<div class="container-fluid bg-tertiary">
    <div class="container section wave">
        <div class="row">
            <div class="col-md-12 text-center">
                <h1>Beneficios</h1>
            </div>

        </div>
        <div class="row">
            <div class="col-md-4">
                <h5 class="text-center">EXPECTATIVA PACIENTE</h5>
                <ul>
                    <li>Aumenta de la calidad percibida y el grado de fidelización del paciente.</li>
                    <li>Empoderar al paciente mediante la corresponsabilización y toma de decisiones compartida.</li>
                    <li>Mejora del nivel de adherencia al tratamiento.</li>
                    <li>Mejora los resultados clínicos obtenidos.</li>

                </ul>
            </div>
            <div class="col-md-4">

            </div>
            <div class="col-md-4">

            </div>
        </div>
    </div>
</div>