import { Component, OnInit } from '@angular/core';
import { ClientsService } from 'src/app/services/clients.service';

@Component({
  selector: 'app-clients',
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.scss']
})
export class ClientsComponent implements OnInit {
  clients;
  constructor(public cs: ClientsService) { }

  ngOnInit(): void {
    window.scrollTo({top: 0});

    this.clients = this.cs.clientsAll;
  }

}
