import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  public contactForm: any = {
    org: '',
    message: '',
    name: '',
    org_role: 0

  }
  msgResult;
  constructor(public firestore: AngularFirestore) { }


  ngOnInit(): void {
    window.scrollTo({top: 0});
  }

  async sendMessage() {
    try {
      await this.firestore.collection('email')
        .add({
          ...this.contactForm,
          date: new Date(),
          source: 'web-athenea'
        })
      this.contactForm = {
        org_role: 0
      };
      this.msgResult = true;
      setTimeout(() => { this.msgResult = false }, 7000)
    } catch (e) {
      window.alert('Ops! Error: sorry, try again later...')
    }
  }
}
