<div class="container-fluid bg-primary">
    <div class="container section wave">
        <div class="row">
            <div class="col-12 text-left">
                <p class="text-goldenRod font-weight-bold text-uppercase">Athenea Solutions</p>
                <h1 class="text-white">{{'footer.cookies' | translate}}</h1>
                <p class="text-white">{{'cookies.text' | translate}}</p>
            </div>
        </div>
    </div>
</div>



<div class="container-fluid">
    <div class="container section wave">
        <div *ngIf="lang === 'es'">
            <h4>¿QUÉ SON LAS COOKIES?</h4>
            <p>El acceso a este Sitio Web puede implicar la utilización de cookies. Las cookies son pequeñas cantidades
                de información que se almacenan en el navegador utilizado por cada Usuario —en los distintos
                dispositivos que pueda utilizar para navegar— para que el servidor recuerde cierta información que
                posteriormente y únicamente el servidor que la implementó leerá. Las cookies facilitan la navegación, la
                hacen más amigable, y no dañan el dispositivo de navegación.
            </p>
            <p>La información recabada a través de las cookies puede incluir la fecha y hora de visitas al Sitio Web,
                las páginas visionadas, el tiempo que ha estado en el Sitio Web y los sitios visitados justo antes y
                después del mismo. Sin embargo, ninguna cookie permite que esta misma pueda contactarse con el número de
                teléfono del Usuario o con cualquier otro medio de contacto personal. Ninguna cookie puede extraer
                información del disco duro del Usuario o robar información personal. La única manera de que la
                información privada del Usuario forme parte del archivo Cookie es que el usuario dé personalmente esa
                información al servidor.
            </p>
            <p> Las cookies que permiten identificar a una persona se consideran datos personales. Por tanto, a las
                mismas les será de aplicación la Política de Privacidad anteriormente descrita. En este sentido, para la
                utilización de las mismas será necesario el consentimiento del Usuario. Este consentimiento será
                comunicado, en base a una elección auténtica, ofrecido mediante una decisión afirmativa y positiva,
                antes del tratamiento inicial, removible y documentado.
            </p>
            <h4>COOKIES PROPIAS</h4>
            <p>Son aquellas cookies que son enviadas al ordenador o dispositivo del Usuario y gestionadas exclusivamente
                por Athenea Solutions para el mejor funcionamiento del Sitio Web. La información que se recaba se emplea
                para mejorar la calidad del Sitio Web y su Contenido y su experiencia como Usuario. Estas cookies
                permiten reconocer al Usuario como visitante recurrente del Sitio Web y adaptar el contenido para
                ofrecerle contenidos que se ajusten a sus preferencias.
            </p>
            <p>La(s) entidad(es) encargada(s) del suministro de cookies podrá(n) ceder esta información a terceros,
                siempre y cuando lo exija la ley o sea un tercero el que procese esta información para dichas entidades.
            </p>

            <h4>COOKIES DE REDES SOCIALES
            </h4>
            <p>Athenea Solutions incorpora plugins de redes sociales, que permiten acceder a las mismas a partir del
                Sitio Web. Por esta razón, las cookies de redes sociales pueden almacenarse en el navegador del Usuario.
                Los titulares de dichas redes sociales disponen de sus propias políticas de protección de datos y de
                cookies, siendo ellos mismos, en cada caso, responsables de sus propios ficheros y de sus propias
                prácticas de privacidad. El Usuario debe referirse a las mismas para informarse acerca de dichas cookies
                y, en su caso, del tratamiento de sus datos personales. Únicamente a título informativo se indican a
                continuación los enlaces en los que se pueden consultar dichas políticas de privacidad y/o de cookies:
            </p>
            <ul>
                <li> Twitter: <a class="text-primary" href="https://twitter.com/es/privacy" target="_blank">
                        https://twitter.com/es/privacy</a>
                </li>
                <li> Instagram: <a class="text-primary" href="https://help.instagram.com/" target="_Blank">
                        https://help.instagram.com</a>
                </li>
                <li> Youtube: <a class="text-primary" href="https://policies.google.com/privacy" target="_blank">
                        https://policies.google.com/privacy</a>
                </li>
                <li> LinkedIn: <a class="text-primary"
                        href="https://www.linkedin.com/legal/cookie-policy?trk=hp-cookies" target="_blank">
                        https://www.linkedin.com/legal/cookie-policy</a>
                </li>
            </ul>

            <h4>DESHABILITAR, RECHAZAR Y ELIMINAR COOKIES</h4>
            <p>El Usuario puede deshabilitar, rechazar y eliminar las cookies —total o parcialmente— instaladas en su
                dispositivo mediante la configuración de su navegador (entre los que se encuentran, por ejemplo, Chrome,
                Firefox, Safari, Explorer). En este sentido, los procedimientos para rechazar y eliminar las cookies
                pueden diferir de un navegador de Internet a otro. En consecuencia, el Usuario debe acudir a las
                instrucciones facilitadas por el propio navegador de Internet que esté utilizando. En el supuesto de que
                rechace el uso de cookies —total o parcialmente— podrá seguir usando el Sitio Web, si bien podrá tener
                limitada la utilización de algunas de las prestaciones del mismo.
            </p>
            <br>
            <br>
            <p>Esta Política de Cookies fue actualizada el día 1 de junio 2021 para adaptarse al
                Reglamento (UE) 2016/679 del Parlamento Europeo y del Consejo, de 27 de abril de 2016, relativo a la
                protección de las personas físicas en lo que respecta al tratamiento de datos personales y a la libre
                circulación de estos datos (RGPD) y a la Ley Orgánica 3/2018, de 5 de diciembre, de Protección de Datos
                Personales y garantía de los derechos digitales.
            </p>
        </div>
        <div *ngIf="lang === 'ca'">
            <h4>QUE SON LES COOKIES?</h4>
            <p> L'accés a aquest lloc web pot implicar la utilització de cookies. Les cookies són petites quantitats
                d'informació que s'emmagatzemen en el navegador utilitzat per cada usuari -en els diferents
                dispositius que pugui utilitzar per navegar- perquè el servidor recordi certa informació que
                posteriorment i únicament el servidor que la va implementar llegirà. Les cookies faciliten la
                navegació, la
                fan més amigable, i no malmeten el dispositiu de navegació.
            </p>
            <p> La informació recollida a través de les galetes pot incloure la data i hora de visites a el Lloc
                Web,
                les pàgines visionades, el temps que ha estat en el Lloc Web i els llocs visitats just abans i
                després del mateix. No obstant això, cap galeta permet que aquesta mateixa pugui contactar-se amb el
                nombre de
                telèfon de l'Usuari o amb qualsevol altre mitjà de contacte personal. Cap cookie pot extreure
                informació del disc dur de l'Usuari o robar informació personal. L'única manera que la
                informació privada de l'Usuari formi part de l'arxiu Cookie és que l'usuari doni personalment aquesta
                informació a servidor.
            </p>
            <p> Les cookies que permeten identificar una persona es consideren dades personals. Per tant, a les
                mateixes els serà d'aplicació la Política de Privacitat anteriorment descrita. En aquest sentit, per
                a la
                utilització de les mateixes serà necessari el consentiment de l'Usuari. Aquest consentiment serà
                comunicat, d'acord amb una elecció autèntica, ofert mitjançant una decisió afirmativa i positiva, abans
                del tractament inicial, removible i documentat.
            </p>
            <h4>COOKIES PRÒPIES</h4>
            <p> Són aquelles galetes que són a l'ordinador o dispositiu de l'Usuari i gestionades exclusivament
                per Athenea Solutions per al millor funcionament del Lloc Web. La informació que es recull
                s'empra
                per millorar la qualitat del Lloc Web i el seu Contingut i la seva experiència com a Usuari.
                Aquestes galetes
                permeten reconèixer a l'Usuari com a visitant recurrent del Lloc Web i adaptar el contingut per
                oferir continguts que s'ajustin a les seves preferències.
            </p>
            <p> La(s) entitat(s) encarregada(s) del subministrament de cookies podrà(n) cedir aquesta
                informació a tercers,
                sempre que ho exigeixi la llei o sigui un tercer el que processi aquesta informació per a
                aquestes entitats.
            </p>

            <h4>COOKIES DE XARXES SOCIALS</h4>
            <p> Athenea Solutions incorpora connectors de xarxes socials, que permeten accedir-hi a partir
                del Lloc Web. Per aquesta raó, les galetes de xarxes socials poden emmagatzemar al navegador de
                l'Usuari.
                Els titulars d'aquestes xarxes socials disposen de les seves pròpies polítiques de protecció
                de dades i de
                galetes, sent ells mateixos, en cada cas, responsables dels seus propis fitxers i de les
                seves pròpies
                pràctiques de privacitat. L'Usuari ha de referir-se a les mateixes per a informar-se sobre
                aquestes cookies
                i, si escau, del tractament de les seves dades personals. Únicament a títol informatiu
                s'indiquen a
                continuació els enllaços en els quals es poden consultar aquestes polítiques de privacitat i
                / o de galetes:
            </p>
            <ul>
                <li> Twitter: <a class="text-primary" href="https://twitter.com/es/privacy" target="_blank">
                        https://twitter.com/es/privacy</a>
                </li>
                <li> Instagram: <a class="text-primary" href="https://help.instagram.com/" target="_Blank">
                        https://help.instagram.com</a>
                </li>
                <li> Youtube: <a class="text-primary" href="https://policies.google.com/privacy" target="_blank">
                        https://policies.google.com/privacy</a>
                </li>
                <li> LinkedIn: <a class="text-primary"
                        href="https://www.linkedin.com/legal/cookie-policy?trk=hp-cookies" target="_blank">
                        https://www.linkedin.com/legal/cookie-policy</a>
                </li>
            </ul>

            <h4>DESACTIVAR, REFUSAR I ELIMINAR COOKIES</h4>
            <p> L'usuari pot desactivar, rebutjar i eliminar les galetes -total o parcialment-
                instal·lades en el seu
                dispositiu mitjançant la configuració del seu navegador (entre els quals es
                troben, per exemple, Chrome,
                Firefox, Safari, Explorer). En aquest sentit, els procediments per rebutjar i
                eliminar les galetes
                poden diferir d'un navegador d'Internet a un altre. En conseqüència, l'Usuari ha
                d'acudir a les
                instruccions facilitades pel propi navegador d'Internet que utilitzeu. En el cas
                que
                rebutgi l'ús de cookies -total o parcialment- podrà seguir utilitzant el Lloc
                Web, si bé podrà tenir
                limitada la utilització d'algunes de les prestacions de la mateixa.
            </p>
            <br>
            <br>
            <p> Aquesta Política de Cookies va ser actualitzada el dia 1 de juny 2021 per
                adaptar-se al
                Reglament (UE) 2016/679 de el Parlament Europeu i de Consell, de 27 d'abril de
                2016, relatiu a la
                protecció de les persones físiques pel que fa a el tractament de dades personals
                ia la lliure
                circulació d'aquestes dades (RGPD) ia la Llei Orgànica 3/2018, de 5 de desembre,
                de Protecció de Dades
                Personals i garantia dels drets digitals.
            </p>
        </div>
    </div>
</div>