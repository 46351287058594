<div class="container-fluid bg-primary">
    <div class="container section wave">
        <div class="row">
            <div class="col-12 text-left">
                <p class="text-goldenRod font-weight-bold text-uppercase">{{'menu.clients' | translate}}</p>
                <h1 class="text-white">{{'clients.title' | translate}}</h1>
                <p class="text-white">{{'clients.text' | translate}}</p>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid">
    <div class="container section wave">
        <div class="row">
            <div class="client-grid pb-5">
                <a *ngFor="let i of clients" [href]="i?.url" target="_blank"><img
                    src="../../assets/clients/{{i?.id}}.png"></a>
            </div>
        </div>
    </div>
</div>