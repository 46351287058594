import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.scss']
})
export class PrivacyComponent implements OnInit {

  lang = 'es';
  constructor(public tr: TranslateService,
    public route: ActivatedRoute) {

  }

  ngOnInit(): void {
    let tmpLang = this.route.snapshot.paramMap.get('lang');
    if (tmpLang) {
      this.lang = tmpLang;
      this.tr.use(this.lang);
    } else {
      this.lang = this.tr.currentLang;
      this.tr.onLangChange.subscribe((tr) => this.lang = tr.lang);
    }
  }
}
