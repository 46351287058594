import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-cookies',
  templateUrl: './cookies.component.html',
  styleUrls: ['./cookies.component.scss']
})
export class CookiesComponent implements OnInit {

  lang = 'es';
  constructor(public tr: TranslateService) { }

  ngOnInit(): void {
    this.lang = this.tr.currentLang;
    console.log("LANG", this.lang)
    this.tr.onLangChange.subscribe((tr)=> this.lang = tr.lang);
  }

}
