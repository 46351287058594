import { Component, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NavService } from './services/nav.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'atheneasolutions';
  isShown: boolean = false;
  profile;
  showToast = true;
  screenWidth;
  screenHeight;
  lang = 'ca';
  cookies = false;

  public scroll = false;
  constructor(
    public ns: NavService,
    public router: Router,
    public tr: TranslateService) {
    this.init();
  }

  async init() {
    let lang = localStorage.getItem('lang');
    if(lang) this.lang = lang;
    this.tr.setDefaultLang(this.lang);
    this.tr.use(this.lang);
    this.ns.hideMenu = false;
  }

  ngOnInit() {
    this.ns.screenSize(window.screen.width);
    this.tr.onLangChange.subscribe((tr)=> this.lang = tr.lang);
    this.cookies = JSON.parse(localStorage.getItem('cookies')) || false;
    if(this.cookies) this.cookies = true;
  }

  @HostListener('window:scroll', ['$event']) onScrollEvent(event) {
    this.scroll = true;
    if (document.documentElement.scrollTop > 50 || document.body.scrollTop > 50) this.scroll = true;
    else this.scroll = false;

  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.screenWidth = window.innerWidth;
    this.ns.screenSize(this.screenWidth)
  }

  changeLang(lang){
    localStorage.setItem('lang', lang);
    this.tr.use(lang);
    this.tr.setDefaultLang(lang)
  }
  acceptCookies(){
    localStorage.setItem('cookies', 'true');
    this.cookies = true;
  }

}
