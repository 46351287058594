import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ClientsService {

  public clients1 = [
    {
      id: "ALTHAIA",
      url: 'https://www.althaia.cat',
      name: 'Althaia'
    },
    {
      id: "ASEPEYO",
      url: 'https://prevencion.asepeyo.es',
      name: 'Asepeyo'
    },
    {
      id: "BELLVITGE",
      url: 'https://bellvitgehospital.cat',
      name: 'Bellvitge Hospital Universitari'
    },
    {
      id: "DOMUSVI",
      url: 'https://www.domusvi.es/',
      name: 'DomusVi'
    },
    {
      id: "ESTEVE",
      url: 'https://www.esteveteijin.com/',
      name: 'Esteve Teijin '
    },
    {
      id: "HOSPITALCLINIC",
      url: 'https://www.clinicbarcelona.org/',
      name: 'Hospital Clínic Barcelona'
    },
    {
      id: "GUTTMANN",
      url: 'https://www.guttmann.com/',
      name: 'Institut Guttmann'
    }, {
      id: "LAUNIO",
      url: 'https://www.uch.cat/',
      name: 'La Unió'
    }, {
      id: "QS",
      url: 'https://www.quironsalud.es/',
      name: 'QuironSalud'
    }
  ];

  public clients2 = [
    {
      id: "ACEBA",
      url: 'https://www.aceba.cat',
      name: 'ACEBA'
    },
    {
      id: "MCCOPERNIC",
      url: 'https://www.mc-mutual.com/',
      name: 'Clínica MC MUTUAL Copérnico'
    },
    {
      id: "CSB",
      url: 'http://www.csb.cat/',
      name: 'Consorci Sanitari de Barcelona'
    },
    {
      id: "CSC",
      url: 'http://www.consorci.org',
      name: 'Consorci de Salut i social de Catalunya'
    },
    {
      id: "HOSPITALMOLLET",
      url: 'https://www.fsm.cat/',
      name: 'Fundació Sanitaria Hospital de Mollet'
    }, {
      id: "LACAIXA",
      url: 'https://fundacionlacaixa.org/es/home',
      name: 'Fundació "la Caixa"'
    },
    {
      id: "CSM",
      url: 'https://www.csdm.cat',
      name: 'Hospital de Mataró - Consorci Sanitaria del Maresme'
    }, {
      id: "ICO",
      url: 'http://ico.gencat.cat/ca/inici',
      name: 'ICO'
    }, {
      id: "PARCTAULI",
      url: 'https://www.tauli.cat/hospital/',
      name: 'Parc Taulí - Hospital Universitari'
    }
  ];

  public clients3 = [
    {
      id: "AISBE",
      url: 'http://salutintegralbcn.gencat.cat/ca/Arees_Integrals_de_Salut/ais-barcelona-esquerra/',
      name: 'AIS Barcelona Esquerra'
    },
    {
      id: "Albera Salut Peralada",
      url: 'http://www.alberasalut.cat/',
      name: 'Albera Salut Peralada'
    }, {
      id: "Balt Medical Spain",
      url: 'https://baltgroup.com/',
      name: 'Balt Medical Spain'
    }, {
      id: "Barnaclinic",
      url: 'https://www.barnaclinic.com/es/',
      name: 'Barnaclinic'
    }, {
      id: "BeHIT",
      url: 'https://www.behit.cat/ES/',
      name: 'BeHit'
    }, {
      id: "Centre Integral Cotxeres",
      url: 'http://www.cotxeres.consorci.org/',
      name: 'Centre Integral Cotxeres'
    },
    {
      id: "CDITARRAGONA",
      url: 'http://cditarragona.es',
      name: 'Centre de Diagnosi per la Imatge Tarragona'
    },
    {
      id: "CFARMACEUTICSBCN",
      url: 'https://www.cofb.org',
      name: 'Col·legi de farmacèutics de Barcelona'
    },
    {
      id: "CSG",
      url: 'https://www.csg.cat/',
      name: 'Consorci Sanitari del Garraf'
    }, {
      id: "CSP",
      url: 'https://www.csap.cat/',
      name: 'Consorci Sanitari Alt Penedès'
    }, {
      id: "CSAPG",
      url: 'https://www.csapg.cat/',
      name: 'Consorci Sanitari Alt Penedès - Garraf'
    }, {
      id: "HMCSM",
      url: 'https://www.csdm.cat/ca/',
      name: 'Hospital de Mataró - Consorci Sanitaria del Maresme'
    },

    {
      id: "CRC",
      url: '',
      name: 'CRC'
    },
    {
      id: "FSM",
      url: 'https://www.fsm.cat/',
      name: 'Fundació Sanitaria Mollet'
    },
    {
      id: "FSHSU",
      url: 'http://www.fsh.cat/',
      name: 'Fundació Sant Hospital la Seu Urgell'
    }, {
      id: "GTMS",
      url: 'http://ics.gencat.cat/ca/lics/memories-dactivitat/memories-territorials/metropolitana-sud/',
      name: 'Gerència Territorial Metropolitana Sud'
    }, {
      id: "HD",
      url: 'https://www.quironsalud.es/hospital-barcelona/es/cartera-servicios/analisis-clinicos',
      name: 'Health Diagnostic'
    },
    {
      id: "CLINICADIAGONAL",
      url: 'https://www.clinicadiagonal.com/',
      name: 'Clínica Diagonal'
    },
    {
      id: "CLINICAGIRONA",
      url: 'https://www.clinicagirona.cat',
      name: 'Clínica Girona'
    },
    {
      id: "DREGEA",
      url: 'https://dralejandroegea.com/',
      name: 'Dr. Alejandro Egea - Urólog'
    },
    {
      id: "HCAMPDEVANOL",
      url: 'http://www.hospitaldecampdevanol.cat',
      name: "Hospital de Campdevànol"
    },
    {
      id: "ACADEMIA",
      url: 'https://www.academia.cat/',
      name: "Acadèmia"
    },
    {
      id: "CAPADRIA",
      url: 'https://capadriaics.wordpress.com',
      name: 'CAP Adrià'
    },
    {
      id: "CAPVALLCARCA",
      url: 'https://aprimariavsg.com',
      name: 'EAP Vallcarca - Sant Gervasi'
    },
    {
      id: "CATALANOBALEAR",
      url: 'http://www.scbcp.cat',
      name: 'Societat Catalano-Balear de Cures Pal·liatives'
    },
    {
      id: "EAPDRETA",
      url: 'https://www.eapdretaeixample.cat/',
      name: 'EAP Dreta Eixample'
    },
    {
      id: "EAPPOBLESEC",
      url: 'http://capleshortes.cat/',
      name: 'EAP Poble-sec'
    },
    {
      id: "EAPSARDENYA",
      url: 'https://www.eapsardenya.cat/',
      name: 'EAP Sardenya'
    },
    {
      id: "EAPSARRIA",
      url: 'https://capsarria.com/',
      name: 'EAP Sarrià Vallvidrera Les Planes'
    }, {
      id: "EAPVIC",
      url: 'http://www.eapvic.com',
      name: 'EAP Vic'
    },
    {
      id: "EPES",
      url: 'http://www.epes.es/',
      name: 'EPES Andalucia'
    },
    {
      id: "PUIGVERT",
      url: 'https://www.fundacio-puigvert.es/',
      name: 'Fundació Puigvert'
    },
    {
      id: "ICATME",
      url: 'https://icatme.com/',
      name: "Institut Català de Traumatologia i Medicina de l'Esport"
    },
    {
      id: "IDIBELL",
      url: 'https://idibell.cat/',
      name: "Institut d'Investigació Biomèdica de Bellvitge"
    },
    {
      id: "IMI",
      url: 'http://www.imatgemedica.cat/',
      name: 'Imatge Mèdica Intercentres'
    },
    {
      id: "IX",
      url: 'http://www.interxarxes.com/',
      name: 'Interxarxes'
    },
    {
      id: "HNP",
      url: 'https://hnpgroup.eu/',
      name: 'Healthcare Network Partners'
    },
    {
      id: "HOSPITALSANTRAFAEL",
      url: 'http://www.hsrafael.com/',
      name: 'Germanes Hospitalàries - Hospital Sant Rafael'
    },
    {
      id: "HOSPITALIGUALADA",
      url: 'https://catsalut.gencat.cat/ca/detalls/articles/SS-ConSanAnoia-HospIgualada',
      name: 'Hospital Universitari d\'Igualada'
    },
    {
      id: "HOSPITALMATARO",
      url: 'https://www.csdm.cat/',
      name: 'Hospital de Mataró - Consorci Sanitaria del Maresme'
    },
    {
      id: "SAAS",
      url: 'https://saas.ad/',
      name: "Hospital Nostra Senyora de Meritxell d'Andorra"
    },
    {
      id: "HOSPITALPALAMOS",
      url: 'https://www.ssibe.cat/hospital-palamos/',
      name: 'Hospital de Palamós'
    },
    {
      id: "HOSPITALPLATO",
      url: 'https://www.clinicbarcelona.org/ca',
      name: 'Hospital Plató'
    },
    {
      id: "HOSPITALSAGRATCOR-GERMANES",
      url: 'http://www.hospitalsagratcormartorell.org/',
      name: 'Hospital Sagrat Cor - Germanes Hospitalaries'
    },
    {
      id: "IBADA",
      url: 'https://ibada.cat/',
      name: 'Ibada'
    },
    {
      id: "NOUSCIMS",
      url: 'https://www.nouscims.com/',
      name: 'Nous Cims"'
    },
    {
      id: "REDOS",
      url: 'http://web.elredos.cat/',
      name: 'Centre de Serveis "El Redós"'
    },
    {
      id: "SANCARE",
      url: 'https://www.sancare.fr/',
      name: 'Sancare'
    },
    {
      id: "SANTJOANDEREUS",
      url: 'https://www.hospitalsantjoan.cat/',
      name: 'Hospital Universitari Sant Joan de Reus'
    },
    {
      id: "SANTPAU",
      url: 'https://www.santpau.cat/',
      name: 'Hospital de la Santa Creu i Sant Pau'
    },
    {
      id: "UDACEBA",
      url: 'https://udaceba.cat/es/',
      name: 'Udaceba Unitat docent'
    },
    {
      id: "SARAN",
      url: 'https://www.conselharan.org/es/el-departament-de-salut-y-el-servicio-aranes-de-benester-e-salut-firman-un-nuevo-convenio-para-promover-la-salud-publica-en-el-valle-de-aran/',
      name: 'Servei Aranés'
    }];

  clientsAll = [...this.clients1, ...this.clients2, ...this.clients3];
  constructor() {
    console.log("CA", this.clientsAll.length)
  }

}
