import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';

import { ParticlesOptions } from '../constants/particlesOptions';
import { animate, group, query, style, transition, trigger } from '@angular/animations';
import { interval } from 'rxjs';
import { NavService } from '../services/nav.service';
import { ClientsService } from '../services/clients.service';
import { TranslateService } from '@ngx-translate/core';



@Component({
	selector: 'app-home',
	templateUrl: './home.component.html',
	styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

	constructor(public ns: NavService,
		public tr: TranslateService,
		public cs: ClientsService) { }

	public options = ParticlesOptions.dark;
	public background = "video"; //vertical //particles
	public clientsFlag = true;

	lang = 'ca';
	@ViewChild("wordCarousel", { static: false }) wordCarouselEl: ElementRef;
	@ViewChild('videoV1') videoV1: ElementRef;
	@ViewChild('videoV2') videoV2: ElementRef;

	@ViewChild('videoH1') videoH1: ElementRef;
	@ViewChild('videoH2') videoH2: ElementRef;

	public wordCarousel =
		{
			es: ["Transformamos", "Modernizamos", "Optimizamos", "Humanizamos", "Digitalizamos"],
			ca: ["Transformem", "Modernitzem", "Optimitzem", "Humanitzem", "Digitalitzem"]
		};//["Consultoría", "Digitalización", "Experiencia de Paciente"];
	wordCounter = 0;
	textIn = true;
	textOut = false;

	horizontalVideo = true;
	verticalVideo = true;
	videoCounterV = 0;
	videoCounterH = 0;

	order = false;

	shapeImage = "../../assets/img/1-home/heart01.svg";
	in = true;
	out = false;

	shapes = [	{
		text1: {
			es: "Experiencia",
			ca: "Experiència"
		},
		text2:{
			es: "de Paciente",
			ca: "de Pacient"
		},
		img: "../../assets/img/1-home/heart01.svg"
	},

	{
		text1: {
			es: "Optimización",
			ca: "Optimització"
		},
		text2:{
			es: "de Procesos",
			ca: "de Processos"
		},
		img: "../../assets/img/1-home/rombo01.svg"
	},
	{
		text1: {
			es: "Digital",
			ca: "Digital"
		},
		text2:{
			es: "Health",
			ca: "Health"
		},
		img: "../../assets/img/1-home/nube01.svg"
	},{
		text1: {
			es: "Sistemas",
			ca: "Sistemes"
		},
		text2:{
			es: "de Gestión",
			ca: "de Gestió"
		},
		img: "../../assets/img/1-home/rodona01.svg"
	},{
		text1: {
			es: "Estrategia y",
			ca: "Estratègia i"
		},
		text2:{
			es: "Organización",
			ca: "Organització"
		},
		img: "../../assets/img/1-home/triangle01.svg"
	}]
	shape = this.shapes[0];
	shapeCounter = 0;


	ngAfterViewInit() {

		try {
			this.videoV1.nativeElement.muted = true;
			this.videoV1.nativeElement.play();
			this.videoV2.nativeElement.muted = true;
			this.videoV2.nativeElement.play();
		} catch (e) { }
		try {
			this.videoH1.nativeElement.muted = true;
			this.videoH1.nativeElement.play();
			this.videoH2.nativeElement.muted = true;
			this.videoH2.nativeElement.play();

		} catch (e) { }

		window.addEventListener('load', async () => {
			let video: any = document.querySelector('video[muted][autoplay]');
			try {
				video.muted = true;
				await video.play();
			} catch (err) {
				if (video)
					video.play()
			}
		});
	}

	async play(event) {
		let video: any = document.querySelector('video[muted][autoplay]');
		try {
			video.muted = true;
			await video.play();
		} catch (err) {
			if (video)
				video.play()
		}
	}

	ngOnInit() {
		this.tr.onLangChange.subscribe(tr => this.lang = tr.lang);
		this.horizontalVideo = true;//"../../assets/video/hospital.mp4";
		this.verticalVideo = true;//"../../assets/video/walk.mp4";

		interval(5000).subscribe(x => {
			this.textIn = !this.textIn;
			this.textOut = !this.textOut;
			setTimeout(() => {
				this.textIn = !this.textIn;
				this.textOut = !this.textOut;
				this.nextWord(null);
			}, 500)
		})

		interval(7000).subscribe(x => {
			if (this.videoCounterV != 1) {
				this.videoV2.nativeElement.currentTime = 0;
				this.verticalVideo = false;//"../../assets/video/screen.mp4";
				this.videoCounterV = 1;
			} else {
				this.videoV1.nativeElement.currentTime = 0;
				this.verticalVideo = true;//"../../assets/video/walk.mp4";
				this.videoCounterV = 0;
			}
		});

		interval(9000).subscribe(x => {
			if (this.videoCounterH != 1) {
				this.videoH2.nativeElement.currentTime = 0;
				this.horizontalVideo = false;//"../../assets/video/hand.mp4";
				this.videoCounterH = 1;
			} else {
				this.videoH1.nativeElement.currentTime = 0;
				this.horizontalVideo = true;//"../../assets/video/hospital.mp4";
				this.videoCounterH = 0;
			}
		})

		interval(6000).subscribe(x => {
			this.out = !this.out;
			this.in = !this.in;
			setTimeout(() => {
				this.out = !this.out;
				this.in = !this.in;
				this.shapeCounter++;
				if (this.shapeCounter === this.shapes.length) this.shapeCounter = 0;
				this.shape = this.shapes[this.shapeCounter];
				this.clientsFlag = !this.clientsFlag;
			}, 500)

		});
	}

	randomNumber(min, max) {
		return Math.random() * (max - min) + min;
	}

	nextWord(event: any) {
		this.wordCounter = (this.wordCounter + 1) % this.wordCarousel[this.lang].length;
	}

	changeBackground(val) {
		this.background = val;
	}

	openEmail() {
		let emailTo = "hola@atheneasolutions.com";
		let emailSub = "Contacto web";

		window.open("mailto:" + emailTo + '?subject=' + emailSub);

	}

}
