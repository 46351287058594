import { HostListener, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class NavService {
  hideMenu = true;
  screenHeight;
  screenWidth;

  small = false;
  threshold = 992;
  constructor() { }


  screenSize(size){
    if(size < this.threshold) this.small = true;
    else this.small = false;
  }

}
