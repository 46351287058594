<div class="container-fluid bg-primary">
    <div class="container section wave">
        <div class="row">
            <div class="col-12 text-left">
                <p class="text-goldenRod font-weight-bold text-uppercase">{{'menu.contact' | translate}}</p>
                <h1 class="text-white">{{'contact.title' | translate}}</h1>
                <p class="text-white">{{'contact.text' | translate}}</p>
            </div>
        </div>
    </div>
</div>

<!-- <div class="container-fluid h-100">
    <div class="container section wave h-100">
        <iframe class="iframe-box" src="https://medxat-com.web.app/form/puBGsQli5juMfd14gYiI"></iframe>
    </div>
</div> -->
<div class="container-fluid contact-background">
    <div class="container section wave">
        <div class="row row d-flex justify-content-center">
            <div class="col-md-4 my-auto text-center mt-1">
                <div class="card card-block d-flex flex-column shadow text-primary">
                    <div class="card-body align-items-center d-flex flex-column justify-content-center">
                        <h4>
                            <ion-icon name="mail-outline" size="large"></ion-icon>
                        </h4>
                        <h5 class="p-2 text-primary body-font">{{'contact.email.text' | translate}}</h5>
                        <a href="mailto:info@atheneasolutions.com" target="_blank"><button type="button"
                                class="btn btn-primary btn-sm">{{'contact.email.button' | translate}}</button></a>

                    </div>
                </div>
            </div>
            <div class="col-md-4 my-auto text-center mt-1">
                <div class="card card-block d-flex flex-column shadow text-primary">
                    <div class="card-body align-items-center d-flex flex-column justify-content-center">
                        <h4>
                            <ion-icon name="phone-portrait-outline" size="large"></ion-icon>
                        </h4>
                        <h5 class="p-2 text-primary body-font">{{'contact.phone.text' | translate}}</h5>
                        <a href="tel:+34931297570" target="_blank" class="text-primary"><button type="button"
                                class="btn btn-primary btn-sm">{{'contact.phone.button' | translate}}</button></a>
                    </div>
                </div>
            </div>
            <div class="col-md-4 my-auto text-center mt-1">
                <div class="card card-block d-flex flex-column shadow text-primary">
                    <div class="card-body align-items-center d-flex flex-column justify-content-center">
                        <h4>
                            <ion-icon name="business-outline" size="large"></ion-icon>
                        </h4>
                        <h5 class="p-2 text-primary body-font">{{'contact.office.text' | translate}}</h5>
                        <a href="https://goo.gl/maps/TvEETXU1GiaHc1Y87" target="_blank" class="text-primary"><button
                                type="button" class="btn btn-primary btn-sm">{{'contact.office.button' |
                                translate}}</button></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
