<div class="container-fluid bg-primary">
    <div class="container section wave">
        <div class="row">
            <div class="col-12 text-left">
                <p class="text-goldenRod font-weight-bold text-uppercase">Athenea Solutions</p>
                <h1 class="text-white">{{'footer.privacy' | translate}}</h1>
                <p class="text-white">{{'privacy.text' | translate}}</p>

            </div>
        </div>
    </div>
</div>



<div class="container-fluid">
    <div class="container section wave">
        <div *ngIf="lang === 'es'">
            <h4>POLÍTICA DE PRIVACIDAD DEL SITIO WEB</h4>
            <p><a href="https://atheneasolutions.com" class="text-primary">https://atheneasolution.com</a></p>

            <h4>PROPÓSITO</h4>
            <p>Desde Athenea Solutions tratamos la información personal proporcionada por las partes interesadas única y
                exclusivamente para el cumplimiento de los servicios de consultoría y diseño que se contratan y, cuando
                corresponda, para mantenerlo informado sobre eventos, noticias, actualizaciones y actividades
                relacionadas con la actividad desarrollada por Athenea Solutions y / o el sector digital. Si nos envía
                su CV, se almacenará para incluirlo en los procesos de selección iniciados por Athenea Solutions.
            </p>

            <h4>I. POLÍTICA DE PRIVACIDAD Y PROTECCIÓN DE DATOS</h4>
            <p>Respetando lo establecido en la legislación vigente, Athenea Solutions (en adelante, también Sitio Web)
                se compromete a adoptar las medidas técnicas y organizativas necesarias, según el nivel de seguridad
                adecuado al riesgo de los datos recogidos.
            </p>

            <h4>RESPONSABLE DEL TRATAMIENTO DE SUS DATOS PERSONALES:</h4>
            <p>El responsable del tratamiento de los datos personales recogidos en Athenea Solutions es: ATHENEA
                SOLUTIONS & TECH, S.L., provista de NIF: B66459843 e inscrita en: Registro Mercantil de Barcelona con
                los siguientes datos registrales: Tomo 44652, Folio 130, Sección 8, cuyo representante es: Athenea
                Solutions (en adelante, Responsable del tratamiento). Sus datos de contacto son los siguientes:
            </p>
            <ul>
                <li>Dirección: Carrer d'Hercegovina 19, despatx 3, 08006 BARCELONA</li>
                <li>Correo electrónico: info@atheneasolutions.com</li>
                <li>Teléfono: 931 297 570</li>
            </ul>

            <h4>DELEGADO DE PROTECCIÓN DE DATOS (DPD):</h4>
            <p>El Delegado de Protección de Datos (DPD, o DPO por sus siglas en inglés) es el encargado de velar por el
                cumplimiento de la normativa de protección de datos a la cual se encuentra sujeta Athenea Solutions. El
                Usuario puede contactar con el DPD designado por el Responsable del tratamiento utilizando los
                siguientes datos de contacto: info@atheneasolutions.com.
            </p>


            <h4>LEYES QUE INCORPORA ESTA POLÍTICA DE PRIVACIDAD</h4>
            <p>Esta política de privacidad está adaptada a la normativa española y europea vigente en materia de
                protección de datos personales en internet. En concreto, la misma respeta las siguientes normas:
            </p>
            <ul>
                <li>El Reglamento (UE) 2016/679 del Parlamento Europeo y del Consejo, de 27 de abril de 2016, relativo a
                    la protección de las personas físicas en lo que respecta al tratamiento de datos personales y a la
                    libre circulación de estos datos (RGPD).
                </li>
                <li>La Ley Orgánica 3/2018, de 5 de diciembre, de Protección de Datos Personales y garantía de los
                    derechos digitales (LOPD-GDD)
                </li>
                <li>El Real Decreto 1720/2007, de 21 de diciembre, por el que se aprueba el Reglamento de desarrollo de
                    la Ley Orgánica 15/1999, de 13 de diciembre, de Protección de Datos de Carácter Personal (RDLOPD).
                </li>
                <li>La Ley 34/2002, de 11 de julio, de Servicios de la Sociedad de la Información y de Comercio
                    Electrónico (LSSI-CE).
                </li>
            </ul>

            <h4>PRINCIPIOS APLICABLES AL TRATAMIENTO DE LOS DATOS PERSONALES</h4>
            <p>El procesamiento de sus datos personales tiene su base legal en el cumplimiento de la asesoría y el
                servicio o en la relación comercial entre los clientes y Athenea Solutions.
            </p>
            <p>Basamos el envío de información sobre eventos, noticias, actualizaciones o actividades relacionadas con
                la actividad desarrollada por Athenea Solutions en el consentimiento solicitado. Puede retirar dicho
                consentimiento en cualquier momento.
            </p>
            <p>Basamos el almacenamiento de datos personales contenidos en Curriculum Vitae en el consentimiento de la
                parte interesada. Estos datos se almacenarán durante un período de un año a partir de la última
                confirmación de interés, sin perjuicio de que el interesado pueda retirar el consentimiento otorgado en
                cualquier momento. La retirada del consentimiento para el almacenamiento de sus datos significará que su
                aplicación no se puede tener en cuenta en los procesos de selección.
            </p>
            <p>El tratamiento de los datos personales del Usuario se someterá a los siguientes principios recogidos en
                el artículo 5 del RGPD y en el artículo 4 y siguientes de la Ley Orgánica 3/2018, de 5 de diciembre, de
                Protección de Datos Personales y garantía de los derechos digitales:
            </p>
            <ul>
                <li>Principio de licitud, lealtad y transparencia: se requerirá en todo momento el consentimiento del
                    Usuario previa información completamente transparente de los fines para los cuales se recogen los
                    datos personales.
                </li>
                <li>Principio de limitación de la finalidad: los datos personales serán recogidos con fines
                    determinados, explícitos y legítimos.
                </li>
                <li>Principio de minimización de datos: los datos personales recogidos serán únicamente los
                    estrictamente necesarios en relación con los fines para los que son tratados.
                </li>
                <li>Principio de exactitud: los datos personales deben ser exactos y estar siempre actualizados.
                </li>
                <li>Principio de limitación del plazo de conservación: los datos personales sólo serán mantenidos de
                    forma que se permita la identificación del Usuario durante el tiempo necesario para los fines de su
                    tratamiento.
                </li>
                <li>Principio de integridad y confidencialidad: los datos personales serán tratados de manera que se
                    garantice su seguridad y confidencialidad.
                </li>
                <li>Principio de responsabilidad proactiva: el Responsable del tratamiento será responsable de asegurar
                    que los principios anteriores se cumplen.
                </li>
            </ul>

            <h4>CATEGORÍAS DE DATOS PERSONALES</h4>
            <p>Las categorías de datos que se tratan en Athenea Solutions son únicamente datos identificativos. En
                ningún caso, se tratan categorías especiales de datos personales en el sentido del artículo 9 del RGPD.
            </p>

            <h4>BASE LEGAL PARA EL TRATAMIENTO DE LOS DATOS PERSONALES</h4>
            <p>La base legal para el tratamiento de los datos personales es el consentimiento. Athenea Solutions se
                compromete a recabar el consentimiento expreso y verificable del Usuario para el tratamiento de sus
                datos personales para uno o varios fines específicos.
                El Usuario tendrá derecho a retirar su consentimiento en cualquier momento. Será tan fácil retirar el
                consentimiento como darlo. Como regla general, la retirada del consentimiento no condicionará el uso del
                Sitio Web.
            </p>

            <h4>DESTINATARIOS DE LOS DATOS PERSONALES</h4>
            <p>Los datos personales del Usuario no serán compartidos con terceros.
                No transferiremos ningún dato personal que nos proporcione a terceros ni a otros Estados sin el
                consentimiento previo y expreso del propietario. Sin perjuicio de lo anterior, le informamos que cierta
                información que proporcione puede ser comunicada, siempre en el marco de la legislación vigente o la
                relación contractual con Athenea Solutions, a los siguientes destinatarios: Administraciones públicas y
                / o cuerpos y fuerzas de seguridad del Estado, cuando Athenea Solutions esté legalmente obligada a
                hacerlo. Otros profesionales del sector legal, cuando sea necesario para la ejecución de los servicios
                contratados.
            </p>

            <h4>DATOS PERSONALES DE MENORES DE EDAD</h4>
            <p>Respetando lo establecido en los artículos 8 del RGPD y 7 de la Ley Orgánica 3/2018, de 5 de diciembre,
                de Protección de Datos Personales y garantía de los derechos digitales, solo los mayores de 14 años
                podrán otorgar su consentimiento para el tratamiento de sus datos personales de forma lícita por Athenea
                Solutions. Si se trata de un menor de 14 años, será necesario el consentimiento de los padres o tutores
                para el tratamiento, y este solo se considerará lícito en la medida en la que los mismos lo hayan
                autorizado.
            </p>

            <h4>SECRETO Y SEGURIDAD DE LOS DATOS PERSONALES</h4>
            <p>Athenea Solutions se compromete a adoptar las medidas técnicas y organizativas necesarias, según el nivel
                de seguridad adecuado al riesgo de los datos recogidos, de forma que se garantice la seguridad de los
                datos de carácter personal y se evite la destrucción, pérdida o alteración accidental o ilícita de datos
                personales transmitidos, conservados o tratados de otra forma, o la comunicación o acceso no autorizados
                a dichos datos.
            </p>
            <p>El Sitio Web cuenta con un certificado SSL (Secure Socket Layer), que asegura que los datos personales se
                transmiten de forma segura y confidencial, al ser la transmisión de los datos entre el servidor y el
                Usuario, y en retroalimentación, totalmente cifrada o encriptada.
            </p>
            <p>Sin embargo, debido a que Athenea Solutions no puede garantizar la inexpugnabilidad de internet ni la
                ausencia total de hackers u otros que accedan de modo fraudulento a los datos personales, el Responsable
                del tratamiento se compromete a comunicar al Usuario sin dilación indebida cuando ocurra una violación
                de la seguridad de los datos personales que sea probable que entrañe un alto riesgo para los derechos y
                libertades de las personas físicas. Siguiendo lo establecido en el artículo 4 del RGPD, se entiende por
                violación de la seguridad de los datos personales toda violación de la seguridad que ocasione la
                destrucción, pérdida o alteración accidental o ilícita de datos personales transmitidos, conservados o
                tratados de otra forma, o la comunicación o acceso no autorizados a dichos datos.
            </p>
            <p>Los datos personales serán tratados como confidenciales por el Responsable del tratamiento, quien se
                compromete a informar de y a garantizar por medio de una obligación legal que dicha confidencialidad sea
                respetada por sus empleados, asociados, y toda persona a la cual le haga accesible la información.
            </p>

            <h4>DERECHOS DERIVADOS DEL TRATAMIENTO DE LOS DATOS PERSONALES</h4>
            <p>El Usuario tiene sobre Athenea Solutions y podrá, por tanto, ejercer frente al Responsable del
                tratamiento los siguientes derechos reconocidos en el RGPD y en la Ley Orgánica 3/2018, de 5 de
                diciembre, de Protección de Datos Personales y garantía de los derechos digitales:
            </p>
            <ul>
                <li>Derecho de acceso: Es el derecho del Usuario a obtener confirmación de si Athenea Solutions está
                    tratando o no sus datos personales y, en caso afirmativo, obtener información sobre sus datos
                    concretos de carácter personal y del tratamiento que Athenea Solutions haya realizado o realice, así
                    como, entre otra, de la información disponible sobre el origen de dichos datos y los destinatarios
                    de las comunicaciones realizadas o previstas de los mismos.
                </li>
                <li>Derecho de rectificación: Es el derecho del Usuario a que se modifiquen sus datos personales que
                    resulten ser inexactos o, teniendo en cuenta los fines del tratamiento, incompletos.
                </li>
                <li>Derecho de supresión ("el derecho al olvido"): Es el derecho del Usuario, siempre que la legislación
                    vigente no establezca lo contrario, a obtener la supresión de sus datos personales cuando estos ya
                    no sean necesarios para los fines para los cuales fueron recogidos o tratados; el Usuario haya
                    retirado su consentimiento al tratamiento y este no cuente con otra base legal; el Usuario se oponga
                    al tratamiento y no exista otro motivo legítimo para continuar con el mismo; los datos personales
                    hayan sido tratados ilícitamente; los datos personales deban suprimirse en cumplimiento de una
                    obligación legal; o los datos personales hayan sido obtenidos producto de una oferta directa de
                    servicios de la sociedad de la información a un menor de 14 años. Además de suprimir los datos, el
                    Responsable del tratamiento, teniendo en cuenta la tecnología disponible y el coste de su
                    aplicación, deberá adoptar medidas razonables para informar a los responsables que estén tratando
                    los datos personales de la solicitud del interesado de supresión de cualquier enlace a esos datos
                    personales.
                </li>
                <li>Derecho a la limitación del tratamiento: Es el derecho del Usuario a limitar el tratamiento de sus
                    datos personales. El Usuario tiene derecho a obtener la limitación del tratamiento cuando impugne la
                    exactitud de sus datos personales; el tratamiento sea ilícito; el Responsable del tratamiento ya no
                    necesite los datos personales, pero el Usuario lo necesite para hacer reclamaciones; y cuando el
                    Usuario se haya opuesto al tratamiento.
                </li>
                <li>Derecho a la portabilidad de los datos: En caso de que el tratamiento se efectúe por medios
                    automatizados, el Usuario tendrá derecho a recibir del Responsable del tratamiento sus datos
                    personales en un formato estructurado, de uso común y lectura mecánica, y a transmitirlos a otro
                    responsable del tratamiento. Siempre que sea técnicamente posible, el Responsable del tratamiento
                    transmitirá directamente los datos a ese otro responsable.
                </li>
                <li>Derecho de oposición: Es el derecho del Usuario a que no se lleve a cabo el tratamiento de sus datos
                    de carácter personal o se cese el tratamiento de los mismos por parte de Athenea Solutions.
                </li>
                <li>Derecho a no ser objeto de una decisión basada únicamente en el tratamiento automatizado, incluida
                    la elaboración de perfiles: Es el derecho del Usuario a no ser objeto de una decisión
                    individualizada basada únicamente en el tratamiento automatizado de sus datos personales, incluida
                    la elaboración de perfiles, existente salvo que la legislación vigente establezca lo contrario.
                </li>
            </ul>

            <p>Puede ejercer los derechos anteriores: ‍i) en nuestra oficina principal escribiendo a Athenea Solutions
                (Carrer d'Hercegovina 19, despatx 3, 08006 Barcelona), adjuntando una fotocopia de su tarjeta de identificación o
                cualquier otro documento de respaldo; o ii) por correo electrónico a la siguiente dirección:
                info@atheneasolutions.com.
            </p>
            <p>También le informamos de su derecho a presentar una queja ante la Agencia Española de Protección de
                Datos, especialmente si considera que no ha obtenido satisfacción en el ejercicio de sus derechos:
                Agencia Española de Protección de Datos C / Jorge Juan, 6. 28001 - Madrid Tel. 901 100 099 - 912 663 517
            </p>

            <h4>ENLACES A SITIOS WEB DE TERCEROS</h4>
            <p>El Sitio Web puede incluir hipervínculos o enlaces que permiten acceder a páginas web de terceros
                distintos de Athenea Solutions, y que por tanto no son operados por Athenea Solutions. Los titulares de
                dichos sitios web dispondrán de sus propias políticas de protección de datos, siendo ellos mismos, en
                cada caso, responsables de sus propios ficheros y de sus propias prácticas de privacidad.
            </p>
            <br>
            <br>
            <p>Esta Política de Privacidad fue actualizada el día 1 de junio 2021 para adaptarse al Reglamento (UE)
                2016/679 del Parlamento Europeo y del Consejo, de 27 de abril de 2016, relativo a la protección de las
                personas físicas en lo que respecta al tratamiento de datos personales y a la libre circulación de estos
                datos (RGPD) y a la Ley Orgánica 3/2018, de 5 de diciembre, de Protección de Datos Personales y garantía
                de los derechos digitales.
            </p>
        </div>
        <div *ngIf="lang === 'ca'">
            <h4>POLÍTICA DE PRIVACITAT DEL LLOC WEB</h4>
            <p> <a href="https://atheneasolutions.com" class="text-primary">https://atheneasolution.com</a> </ p>

            <h4>PROPÒSIT</h4>
            <p>Des Athenea Solutions tractem la informació personal proporcionada per les parts interessades únicament i
                exclusivament per al compliment dels serveis de consultoria i disseny que es contracten i, quan
                correspongui, per mantenir-lo informat sobre esdeveniments, notícies, actualitzacions i activitats
                relacionades amb l'activitat desenvolupada per Athenea Solutions i / o el sector digital. Si ens envia
                el seu CV, s'emmagatzemarà per incloure-ho en els processos de selecció iniciats per Athenea Solutions.
            </p>

            <h4>I. POLÍTICA DE PRIVACITAT I PROTECCIÓ DE DADES</h4>
            <p>Respectant el que estableix la legislació vigent, Athenea Solutions (en endavant, també Lloc Web)
                es compromet a adoptar les mesures tècniques i organitzatives necessàries, segons el nivell de seguretat
                adequat a el risc de les dades recollides.
            </p>

            <h4>RESPONSABLE DEL TRACTAMENT DE LES SEVES DADES PERSONALS:</h4>
            <p>El responsable del tractament de les dades personals recollides en Athenea Solutions és: ATHENEA
                SOLUTIONS & TECH, SL, proveïda de NIF: B66459843 i inscrita en: Registre Mercantil de Barcelona amb
                les següents dades registrals: Tom 44652, Foli 130, Secció 8, el representant és: Athenea
                Solutions (en endavant, responsable del tractament). Les seves dades de contacte són les següents:
            </p>
            <ul>
                <li>Direcció: Carrer d'Hercegovina 19, despatx 3, 08006 BARCELONA</li>
                <li>Correu electrònic: info@atheneasolutions.com</li>
                <li>Telèfon: 931.297.570</li>
            </ul>

            <h4>DELEGAT DE PROTECCIÓ DE DADES (DPD):</h4>
            <p>El Delegat de Protecció de Dades (DPD, o DPO per les sigles en anglès) és l'encarregat de vetllar pel
                compliment de la normativa de protecció de dades a la qual es troba subjecta Athenea Solutions. L'Usuari
                pot contactar amb el DPD designat pel Responsable del tractament utilitzant els
                següents dades de contacte: info@atheneasolutions.com.
            </p>


            <h4>LLEIS QUE INCORPORA AQUESTA POLÍTICA DE PRIVACITAT</h4>
            <p>Aquesta política de privacitat està adaptada a la normativa espanyola i europea vigent en matèria de
                protecció de dades personals a internet. En concret, la mateixa respecta les normes següents:
            </p>
            <ul>
                <li>El Reglament (UE) 2016/679 de el Parlament Europeu i de Consell, de 27 d'abril de 2016, relatiu a
                    la protecció de les persones físiques pel que fa a el tractament de dades personals ia la
                    lliure circulació d'aquestes dades (RGPD).
                </li>
                <li>La Llei Orgànica 3/2018, de 5 de desembre, de Protecció de Dades Personals i garantia dels
                    drets digitals (LOPD-GDD)
                </li>
                <li>El Reial Decret 1720/2007, de 21 de desembre, pel qual s'aprova el Reglament de desenvolupament de
                    la Llei Orgànica 15/1999, de 13 de desembre, de protecció de dades de caràcter personal (RDLOPD).
                </li>
                <li>La Llei 34/2002, de 11 de juliol, de Serveis de la Societat de la Informació i de Comerç
                    Electrònic (LSSI-CE).
                </li>
            </ul>

            <h4>PRINCIPIS APLICABLES AL TRACTAMENT DE LES DADES PERSONALS</h4>
            <p>El processament de les seves dades personals té la seva base legal en el compliment de l'assessoria i el
                servei o en la relació comercial entre els clients i Athenea Solutions.
            </p>
            <p>Basem l'enviament d'informació sobre esdeveniments, notícies, actualitzacions o activitats relacionades
                amb
                l'activitat desenvolupada per Athenea Solutions en el consentiment sol·licitat. Pot retirar dit
                consentiment en qualsevol moment.
            </p>
            <p>Basem l'emmagatzematge de dades personals incloses en currículum en el consentiment de la
                part interessada. Aquestes dades s'emmagatzemaran durant un període d'un any a partir de l'última
                confirmació d'interès, sense perjudici que l'interessat pugui retirar el consentiment atorgat en
                qualsevol moment. La retirada del consentiment per a l'emmagatzematge de les seves dades significarà
                que el
                seu
                aplicació no es pot tenir en compte en els processos de selecció.
            </p>
            <p>El tractament de les dades personals de l'Usuari es sotmetrà als següents principis recollits en
                l'article 5 del RGPD i en l'article 4 i següents de la Llei Orgànica 3/2018, de 5 de desembre, de
                Protecció de Dades Personals i garantia dels drets digitals:
            </p>
            <ul>
                <li>Principi de licitud, lleialtat i transparència: es requerirà en tot moment el consentiment de l'
                    Usuari prèvia informació completament transparent dels fins per als quals es recullen els
                    dades personals.
                </li>
                <li>Principi de limitació de la finalitat: les dades personals seran recollides amb fins
                    determinats, explícits i legítims.
                </li>
                <li>Principi de minimització de dades: les dades personals recollides seran únicament els
                    estrictament necessaris en relació amb els fins per als quals són tractats.
                </li>
                <li>Principi d'exactitud: les dades personals han de ser exactes i estar sempre actualitzats.
                </li>
                <li>Principi de limitació del termini de conservació: les dades personals només seran mantinguts de
                    manera que es permeti la identificació de l'Usuari durant el temps necessari per als fins de la seva
                    tractament.
                </li>
                <li>Principi d'integritat i confidencialitat: les dades personals seran tractades de manera que es
                    garanteixi la seva seguretat i confidencialitat.
                </li>
                <li>Principi de responsabilitat proactiva: el responsable del tractament serà responsable d'assegurar
                    que els principis anteriors es compleixen.
                </li>
            </ul>

            <h4>CATEGORIES DE DADES PERSONALS</h4>
            <p>Les categories de dades que es tracten en Athenea Solutions són únicament dades identificatives. En
                cap cas, es tracten categories especials de dades personals en el sentit de l'article 9 del RGPD.
            </p>

            <h4>BASE LEGAL PER AL TRACTAMENT DE LES DADES PERSONALS</h4>
            <p>La base legal per al tractament de les dades personals és el consentiment. Athenea Solutions es
                compromet a demanar el consentiment exprés i verificable de l'Usuari per al tractament de les seves
                dades personals per a un o diversos fins específics.
                L'Usuari tindrà dret a retirar el seu consentiment en qualsevol moment. Serà tan fàcil retirar el
                consentiment com donar-lo. Com a regla general, la retirada del consentiment no condicionarà l'ús del
                Lloc Web.
            </p>

            <h4>DESTINATARIS DE LES DADES PERSONALS</h4>
            <p>Les dades personals de l'Usuari no seran compartides amb tercers.
                No transferirem cap dada personal que ens proporcioni a tercers ni a altres estats sense el
                consentiment previ i exprés del propietari. Sense perjudici de l'anterior, l'informem que certa
                informació que proporcioni pot ser comunicada, sempre en el marc de la legislació vigent o la
                relació contractual amb Athenea Solutions, als següents destinataris: Administracions públiques i
                / O cossos i forces de seguretat de l'Estat, quan Athenea Solutions estigui legalment obligada a
                fer-ho. Altres professionals de sector legal, quan sigui necessari per a l'execució dels serveis
                contractats.
            </p>

            <h4>DADES PERSONALS DE MENORS D'EDAT</h4>
            <p>Respectant el que estableixen els articles 8 de la RGPD i 7 de la Llei Orgànica 3/2018, de 5 de desembre,
                de Protecció de Dades Personals i garantia dels drets digitals, només els majors de 14 anys
                podran atorgar el seu consentiment per al tractament de les seves dades personals de forma lícita per
                Athenea
                Solutions. Si es tracta d'un menor de 14 anys, serà necessari el consentiment dels pares o tutors
                per al tractament, i aquest sol es considerarà lícit en la mesura en què els mateixos ho hagin
                autoritzat.
            </p>

            <h4>SECRET I SEGURETAT DE LES DADES PERSONALS</h4>
            <p>Athenea Solutions es compromet a adoptar les mesures tècniques i organitzatives necessàries, segons el
                nivell
                de seguretat adequat a el risc de les dades recollides, de manera que es garanteixi la seguretat dels
                dades de caràcter personal i s'eviti la destrucció, pèrdua o alteració accidental o il·lícita de dades
                personals transmesos, conservats o tractats d'una altra manera, o la comunicació o accés no autoritzats
                a aquestes dades.
            </p>
            <p>El lloc web disposa d'un certificat SSL (Secure Socket Layer), que assegura que les dades personals es
                transmeten de forma segura i confidencial, al realitzar la transmissió de les dades entre el servidor i
                el
                Usuari, i en retroalimentació, totalment xifrada o encriptada.
            </p>
            <p>No obstant això, pel fet que Athenea Solutions no pot garantir la inexpugnabilitat de internet ni la
                absència total de hackers o d'altres que accedeixin de manera fraudulenta a les dades personals, el
                Responsable
                del tractament es compromet a comunicar a l'Usuari sense dilació indeguda quan passi una violació
                de la seguretat de les dades personals que sigui probable que comporti un alt risc per als drets i
                llibertats de les persones físiques. Seguint el que estableix l'article 4 del RGPD, s'entén per
                violació de la seguretat de les dades personals tota violació de la seguretat que ocasioni la
                destrucció, pèrdua o alteració accidental o il·lícita de dades personals transmeses, conservats o
                tractats d'una altra manera, o la comunicació o accés no autoritzats a les esmentades dades.
            </p>
            <p>Les dades personals seran tractades com a confidencials pel responsable del tractament, que es va
                compromet a informar de ia garantir per mitjà d'una obligació legal que aquesta confidencialitat sigui
                respectada pels seus empleats, associats, i tota persona a la qual li faci accessible la informació.
            </p>

            <h4>DRETS DERIVATS DEL TRACTAMENT DE LES DADES PERSONALS</h4>
            <p>L'Usuari té sobre Athenea Solutions i podrà, per tant, exercir davant el responsable del
                tractament els següents drets reconeguts en el RGPD i en la Llei Orgànica 3/2018, de 5 de
                desembre, de Protecció de Dades Personals i garantia dels drets digitals:
            </p>
            <ul>
                <li>Dret d'accés: És el dret de l'Usuari a obtenir confirmació de si Athenea Solutions està
                    tractant o no les seves dades personals i, en cas afirmatiu, obtenir informació sobre les seves
                    dades
                    concrets de caràcter personal i de el tractament que Athenea Solutions hagi realitzat o realitzi,
                    així
                    com, entre una altra, de la informació disponible sobre l'origen de les dades i els destinataris
                    de les comunicacions realitzades o previstes dels mateixos.
                </li>
                <li>Dret de rectificació: És el dret de l'Usuari a que es modifiquin les seves dades personals que
                    siguin inexactes o, tenint en compte els caps de el tractament, incomplets.
                </li>
                <li>Dret de supressió ("el dret a l'oblit"): És el dret de l'Usuari, sempre que la legislació
                    vigent no estableixi el contrari, a obtenir la supressió de les seves dades personals quan aquests
                    ja
                    no siguin necessaris per als fins per als quals van ser recollides o tractades; l'Usuari hagi
                    retirat el seu consentiment al tractament i aquest no compti amb una altra base legal; l'Usuari
                    s'oposi
                    a el tractament i no hi hagi un altre motiu legítim per continuar amb el mateix; les dades personals
                    hagin estat tractats il·lícitament; les dades personals hagin de suprimir-se en compliment d'una
                    obligació legal; o les dades personals hagin estat obtinguts producte d'una oferta directa de
                    serveis de la societat de la informació a un menor de 14 anys. A més de suprimir les dades, el
                    Responsable del tractament, tenint en compte la tecnologia disponible i el cost de la seva
                    aplicació, ha d'adoptar mesures raonables per informar els responsables que estiguin tractant
                    les dades personals de la sol·licitud de l'interessat de supressió de qualsevol enllaç a aquestes
                    dades
                    personals.
                </li>
                <li>Dret a la limitació del tractament: És el dret de l'Usuari a limitar el tractament de les seves
                    dades personals. L'Usuari té dret a obtenir la limitació del tractament quan s'impugni la
                    exactitud de les seves dades personals; el tractament sigui il·lícit; el responsable del tractament
                    ja
                    no
                    necessiti les dades personals, però l'Usuari ho necessiti per fer reclamacions; i quan el
                    Usuari s'hagi oposat a el tractament.
                </li>
                <li>Dret a la portabilitat de les dades: En el cas que el tractament s'efectuï per mitjans
                    automatitzats, l'Usuari tindrà dret a rebre del responsable del tractament les seves dades
                    personals en un format estructurat, d'ús comú i lectura mecànica, ja transmetre'ls a un altre
                    responsable del tractament. Sempre que sigui tècnicament possible, el responsable del tractament
                    transmetrà directament les dades a aquest altre responsable.
                </li>
                <li>Dret d'oposició: És el dret de l'Usuari a que no es dugui a terme el tractament de les seves dades
                    de caràcter personal o se cessi el tractament dels mateixos per part de Athenea Solutions.
                </li>
                <li>Dret a no ser objecte d'una decisió basada únicament en el tractament automatitzat, inclosa
                    l'elaboració de perfils: És el dret de l'Usuari a no ser objecte d'una decisió
                    individualitzada basada únicament en el tractament automatitzat de les seves dades personals,
                    inclosa
                    l'elaboració de perfils, existent llevat que la legislació vigent estableixi el contrari.
                </li>
            </ul>

            <p>Pot exercir els drets anteriors: i) a la nostra oficina principal escrivint a Athenea Solutions
                (Carrer d'Hercegovina 19, despatx 3, 08006 Barcelona), adjuntant una fotocòpia de la targeta de identificació o
                qualsevol altre document de suport; o ii) per correu electrònic a la següent adreça:
                info@atheneasolutions.com.
            </p>
            <p>També li informem del seu dret a presentar una queixa davant l'Agència Espanyola de Protecció de
                Dades, especialment si considera que no ha obtingut satisfacció en l'exercici dels seus drets:
                Agència Espanyola de Protecció de Dades C / Jorge Juan, 6. 28001 - Madrid Tel. 901.100.099 - 912.663.517
            </p>

            <h4>ENLLAÇOS A LLOCS WEB DE TERCERS</h4>
            <p>El lloc web pot incloure enllaços o enllaços que permeten accedir a pàgines web de tercers
                diferents de Athenea Solutions, i que per tant no són operats per Athenea Solutions. Els titulars de
                aquests llocs web disposaran de les seves pròpies polítiques de protecció de dades, sent ells mateixos,
                en
                cada cas, responsables dels seus propis fitxers i de les seves pròpies pràctiques de privacitat.
            </p>
            <br>
            <br>
            <p>Aquesta política de privacitat va ser actualitzada el dia 1 de juny 2021 per adaptar-se al Reglament
                (UE)
                2016/679 de el Parlament Europeu i de Consell, de 27 d'abril de 2016, relatiu a la protecció de les
                persones físiques pel que fa a el tractament de dades personals ia la lliure circulació d'aquestes
                dades (RGPD) ia la Llei Orgànica 3/2018, de 5 de desembre, de Protecció de Dades Personals i garantia
                dels drets digitals.
            </p>

        </div>
    </div>
</div>